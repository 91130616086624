import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';
import { Button, TableColumnsType } from 'antd'; // Importing Ant Design button
import { ValidatedBlobField, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntity, reset, updateEntity } from './import-eleve.reducer';
import { getEntities as getGroupesEntities } from 'app/entities/groupe/groupe.reducer';
import { getNiveauEntities as getNiveauEntities } from 'app/entities/niveau/niveau.reducer';
import Papa from 'papaparse';

export const ImportEleveUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = true;

  const loading = useAppSelector(state => state.importEleve.loading);
  const updating = useAppSelector(state => state.importEleve.updating);
  const updateSuccess = useAppSelector(state => state.importEleve.updateSuccess);

  const groupeList = useAppSelector(state => state.groupe.entities);
  const niveauList = useAppSelector(state => state.niveau.entities);

  const [selectedNiveau, setSelectedNiveau] = useState('');

  const handleClose = () => {
    setParsedData(null);
    setTableRows(null);
    setValues(null);
  };

  const getAllEntities = () => {
    dispatch(getGroupesEntities({}));
    dispatch(getNiveauEntities({}));
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    getAllEntities();
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      setParsedData([]);

      // Filtered Column Names
      setTableRows([]);

      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {};

  const handleNiveauChange = event => {
    setSelectedNiveau(event.target.value);
  };

  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const changeHandler = event => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      encoding: 'ISO-8859-1',
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);
        // Filtered Values
        setValues(valuesArray);
      },
    });
  };
  const columns: TableColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
  ];

  const filteredGroupeList = groupeList.filter(groupe => groupe.niveau && groupe.niveau.id.toString() === selectedNiveau);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="goSchoolApp.importEleve.home.createOrEditLabel" data-cy="ImportEleveCreateUpdateHeading">
            Import des èleves
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="4">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={{}} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="import-eleve-id" label="Numéro" validate={{ required: true }} />
              ) : null}

              <ValidatedField
                onChange={handleNiveauChange}
                id="niveau"
                name="niveau"
                data-cy="niveau"
                label="Niveau"
                type="select"
                validate={{ required: true }} // Added required validation
              >
                <option value="" key="0" />
                {niveauList
                  ? niveauList.map(niveau => (
                      <option key={niveau.id} value={niveau.id}>
                        {niveau.libelle}
                      </option>
                    ))
                  : null}
              </ValidatedField>

              <ValidatedField
                id="groupe"
                name="groupe"
                data-cy="groupe"
                label="Groupe"
                type="select"
                validate={{ required: true }} // Added required validation
              >
                <option value="" key="0" />
                {filteredGroupeList
                  ? filteredGroupeList.map(groupe => (
                      <option key={groupe.id} value={groupe.id}>
                        {groupe.nom}
                      </option>
                    ))
                  : null}
              </ValidatedField>

              <ValidatedBlobField
                onChange={changeHandler}
                label="Fichier"
                id="import-eleve-fichier"
                name="fichier"
                data-cy="fichier"
                openActionLabel="Ouvrir"
                validate={{ required: true }} // Added required validation
              />

              <Button type="primary" id="save-entity" data-cy="entityCreateSaveButton" htmlType="submit" loading={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
        <Col md="8">
          <div className="table-responsive">
            {tableRows && tableRows.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    {tableRows.map((rows, index) => {
                      return (
                        <th className="hand" key={index}>
                          {rows}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {values.map((value, i) => (
                    <tr key={i}>
                      {value.map((val, i) => {
                        return <td key={i}>{val}</td>;
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImportEleveUpdate;
