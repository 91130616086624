import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input, Modal } from 'antd';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'reactstrap';
import { createSousMatiere, deleteSousMatiere, getSousMatiereList } from 'app/entities/matiere/sous-matiere.reducer';

const CreateSousMatiereModal = ({ show, handleClose, matiere }) => {
  const matiereList = useAppSelector(state => state.sousMatiere.entities);
  const loading = useAppSelector(state => state.sousMatiere.loading);
  const dispatch = useAppDispatch();

  const saveEntity = values => {
    console.log('Success:', values);

    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...values,
      matiere: matiere,
      matiereId: matiere?.id,
    };
    dispatch(createSousMatiere(entity));
  };
  const handleDelete = id => {
    dispatch(
      deleteSousMatiere({
        matiereId: matiere?.id,
        id: id,
      }),
    );
  };

  const getAllEntities = () => {
    console.info('matiere?.id');
    console.info(matiere?.id);
    dispatch(
      getSousMatiereList({
        sort: 'asc',
        matiereId: matiere?.id,
      }),
    );
  };

  useEffect(() => {
    if (matiere?.id) getAllEntities();
  }, [matiere?.id]);

  return (
    <Modal title="Créer un nouveau sous-matière" open={show} onOk={handleClose} onCancel={handleClose}>
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} onFinish={saveEntity} autoComplete="off">
        <Form.Item label="sous-matière" name="nom" rules={[{ required: true, message: 'Veuillez entrer une sous-matière' }]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <FontAwesomeIcon icon="save" />
            &nbsp; Sauvegarder
          </Button>
        </Form.Item>
      </Form>

      <div className="table-responsive">
        {matiereList && matiereList.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th className="hand">ID</th>
                <th className="hand">Nom</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {matiereList.map((matiere, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{matiere.id}</td>
                  <td>{matiere.nom}</td>
                  <td>
                    <Button icon={<FontAwesomeIcon color={'red'} icon={faRemove} />} onClick={() => handleDelete(matiere.id)}></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">Aucun sous-matière</div>
        )}
      </div>
    </Modal>
  );
};

export default CreateSousMatiereModal;
