import './niveau.scss';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { PopconfirmProps } from 'antd';
import { Alert, Badge, Button, Col, List, message, notification, Popconfirm, Row, Space, Tag } from 'antd';
import { getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort, faSortDown, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import CreateNiveauScolaireModal from 'app/modules/modals/CreateNiveauScolaireModal';
import { deleteEntity, getNiveauEntities } from './niveau.reducer';
import CreateGroupeModal from 'app/modules/modals/CreateGroupeModal';
import { getServiceEtablissementEntities } from 'app/entities/service-etablisement/service-etablisement.reducer';
import UpdateTarifSerivice from 'app/modules/modals/UpdateTarifSerivice';
import { getEntities as getAnneeScolaire } from 'app/entities/annee-scolaire/annee-scolaire.reducer';

import { GoLoading } from 'app/shared/loading';
import { INiveau } from 'app/shared/model/niveau.model';

export const Niveau = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [currentNiveau, setCurrentNiveau] = useState(null);
  //Niveau
  const niveauList = useAppSelector(state => state.niveau.entities);
  const loading = useAppSelector(state => state.niveau.loading);
  const totalItems = useAppSelector(state => state.niveau.totalItems);
  const anneeScolaireList = useAppSelector(state => state.anneeScolaire.entities);

  // Service Etablissement
  const serviceEtablisementList = useAppSelector(state => state.serviceEtablisement.entities);
  const loadingServiceEtablisementList = useAppSelector(state => state.serviceEtablisement.loading);
  const totalItemsServiceEtablisementList = useAppSelector(state => state.serviceEtablisement.totalItems);

  const [currentNiveauId, setCurrentNiveauId] = useState(null);
  const [showModalCreateNiveau, setShowModalCreateNiveau] = useState(false);
  const [showModalGroupe, setShowModalGroupe] = useState(false);
  const [showModalService, setShowModalService] = useState(false);

  const hasCurrentAnneeScolaire = anneeScolaireList.some(annee => annee.isCurrent === true);

  const [api, contextHolder] = notification.useNotification();

  const notifyNoCurrentAnnee = () => {
    api.warning({
      message: 'Notification',
      description: 'Aucune Année Scolaire Courante.',
      placement: 'topLeft',
    });
  };

  const handleOpenModal = () => {
    if (hasCurrentAnneeScolaire) {
      setShowModalCreateNiveau(true);
    } else {
      notifyNoCurrentAnnee();
    }
  };

  const handleCloseModal = () => {
    setCurrentNiveau(null);
    setShowModalCreateNiveau(false);
    setShowModalGroupe(false);
    setShowModalService(false);
  };

  const handleOpenModalService = niveau => {
    setCurrentNiveau(niveau);
    setShowModalService(true);
  };

  const handleCloseModalService = () => {
    setCurrentNiveau(null);
    setShowModalService(false);
  };

  const confirm: PopconfirmProps['onConfirm'] = e => {
    dispatch(deleteEntity(currentNiveauId));
    message.success('Task deleted successfully');
  };

  const cancel: PopconfirmProps['onCancel'] = e => {
    message.error('Deletion cancelled');
  };

  const idNiveau = id => {
    navigate(`/niveau/${id}/delete`);
  };

  const handleAddGroupeModal = niveau => {
    setCurrentNiveau(niveau);
    setShowModalGroupe(true);
  };

  const getAllEntities = () => {
    dispatch(
      getNiveauEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  useEffect(() => {
    dispatch(getAnneeScolaire({}));
  }, []);

  const getServiceEtablissementsAllEntities = () => {
    dispatch(
      getServiceEtablissementEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    getServiceEtablissementsAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  useEffect(() => {
    document.getElementById('actualiser-button')?.click();
  }, []);

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const columns = [
    {
      title: (
        <div onClick={sort('id')} className="hand">
          Code <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button type="link" onClick={() => navigate(`/niveau/${record.id}`)}>
          {text}
        </Button>
      ),
    },
    {
      title: (
        <div onClick={sort('libelle')} className="hand">
          Libelle <FontAwesomeIcon icon={getSortIconByFieldName('libelle')} />
        </div>
      ),
      dataIndex: 'libelle',
      key: 'libelle',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className="btn-group" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button
            style={{ color: 'green' }}
            type="link"
            onClick={() => handleAddGroupeModal(record)}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            Groupes
          </Button>
          <Button type="link" onClick={() => handleOpenModalService(record)} icon={<FontAwesomeIcon icon={faPlus} />}>
            Services
          </Button>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => idNiveau(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger size="small" icon={<FontAwesomeIcon icon={faTrash} />} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <h2 id="niveau-heading" data-cy="NiveauHeading">
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="cyan">
          Niveaux
        </Tag>
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button id="actualiser-button" className="me-2" type="primary" onClick={handleSyncList} loading={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Actualiser la liste
          </Button>
          <Button type="primary" onClick={handleOpenModal}>
            <FontAwesomeIcon icon="plus" />
            &nbsp; Créer un nouveau Niveau
          </Button>
          <CreateNiveauScolaireModal show={showModalCreateNiveau} handleClose={handleCloseModal} />
        </div>
      </h2>
      <UpdateTarifSerivice niveau={currentNiveau} show={showModalService} handleClose={handleCloseModal} />
      <CreateGroupeModal niveau={currentNiveau} show={showModalGroupe} handleClose={handleCloseModal} />

      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : niveauList && niveauList.length > 0 ? (
          <List
            grid={{
              gutter: 16,
              xs: 1, // 1 column on extra small screens (<576px)
              sm: 1, // 1 column on small screens (≥576px)
              md: 2, // 2 columns on medium screens (≥768px)
              lg: 2, // 2 columns on large screens (≥992px)
              xl: 3, // 2 columns on extra large screens (≥1200px)
            }}
            dataSource={niveauList}
            renderItem={(item: INiveau) => {
              return (
                <List.Item
                  key={item.id}
                  className="hoverable-list-item"
                  onClick={() => navigate(`/niveau/${item.id}`)}
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Badge
                        count={item.libelle}
                        style={{
                          color: '#fff',
                          marginBottom: '8px',
                        }}
                      />
                    </Col>
                    <Col>
                      <Space size="small">
                        <Button
                          style={{ color: 'green' }}
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            handleAddGroupeModal(item);
                          }}
                          icon={<FontAwesomeIcon icon={faPlus} />}
                        >
                          Groupes
                        </Button>
                        <Button
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenModalService(item);
                          }}
                          icon={<FontAwesomeIcon icon={faPlus} />}
                        >
                          Services
                        </Button>

                        <Button
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            idNiveau(item.id);
                          }}
                          danger
                          size="small"
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        />
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Alert message="Aucun niveau trouvé" type="warning" showIcon />
        )}
      </div>
    </div>
  );
};

export default Niveau;
