import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Eleve from './eleve';
import Utilisateur from './utilisateur';
import EtatServiceMois from './etat-service-mois';
import MoisEleve from './mois-eleve';
import AbsenceEleve from './absence-eleve';
import Devoir from './devoir';
import Salle from './salle';
import Etablissement from './etablissement';
import ImportEleve from './import-eleve';
import Tarif from './tarif';
import EmploiTemps from './emploi-temps';
import EmploiTempsLigne from './emploi-temps-ligne';
import Controle from './controle';
import ServiceEtablisement from './service-etablisement';
import AnneeScolaire from './annee-scolaire';
import Groupe from './groupe';
import Matiere from './matiere';
import Niveau from './niveau';
import Caisse from './caisse';
import Message from './message';
import Annonce from './annonce';
import Tuteur from './tuteur';
import Prof from './prof';
import Recu from './recu';
import Quiz from './quiz';
import Galerie from './galerie';
import Ressource from './ressource';
import Tresorerie from 'app/modules/tresorerie/tresorerie';
import PayeeImpayee from 'app/modules/tresorerie/PayeeImpayee';
import Garderie from 'app/entities/garderie/Garderie';
import Historique from 'app/entities/garderie/Historique';
import Screen from 'app/entities/garderie/Screen';
import DevoirSurv from 'app/entities/gestion-notes/DevoirSurv';
import Notes from 'app/entities/gestion-notes/Notes';
import DevoirNote from 'app/entities/gestion-notes/DevoirNote';
import AuditLog from './audit-log';
import CahierTransmission from 'app/entities/cahier-transmission';
import PageMobile from 'app/entities/page-mobile';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="eleve/*" element={              
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO, AUTHORITIES.COMPTABLE]}>
                <Eleve />
              </PrivateRoute>}/>
        <Route
          path="utilisateur/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Utilisateur />
            </PrivateRoute>
          }
        />
        <Route
          path="etat-service-mois/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.COMPTABLE]}>
              <EtatServiceMois />
            </PrivateRoute>
          }
        />
        <Route
          path="mois-eleve/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.COMPTABLE]}>
              <MoisEleve />
            </PrivateRoute>
          }
        />
        <Route
          path="absence-eleve/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <AbsenceEleve />
            </PrivateRoute>
          }
        />
        <Route
          path="devoir/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Devoir />
            </PrivateRoute>
          }
        />
        <Route
          path="salle/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Salle />
            </PrivateRoute>
          }
        />
        <Route
          path="etablissement/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Etablissement />
            </PrivateRoute>
          }
        />
        <Route
          path="import-eleve/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <ImportEleve />
            </PrivateRoute>
          }
        />
        <Route
          path="tarif/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.COMPTABLE]}>
              <Tarif />
            </PrivateRoute>
          }
        />
        <Route
          path="emploi-temps/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <EmploiTemps />
            </PrivateRoute>
          }
        />
        <Route
          path="emploi-temps-ligne/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <EmploiTempsLigne />
            </PrivateRoute>
          }
        />
        <Route
          path="controle/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Controle />
            </PrivateRoute>
          }
        />
        <Route
          path="service-etablisement/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <ServiceEtablisement />
            </PrivateRoute>
          }
        />
        <Route
          path="annee-scolaire/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <AnneeScolaire />
            </PrivateRoute>
          }
        />
        <Route
          path="groupe/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Groupe />
            </PrivateRoute>
          }
        />
        <Route
          path="matiere/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Matiere />
            </PrivateRoute>
          }
        />
        <Route
          path="niveau/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Niveau />
            </PrivateRoute>
          }
        />
        <Route
          path="caisse/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.COMPTABLE]}>
              <Caisse />
            </PrivateRoute>
          }
        />
        <Route
          path="message/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Message />
            </PrivateRoute>
          }
        />
        <Route
          path="annonce/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Annonce />
            </PrivateRoute>
          }
        />
        <Route
          path="tuteur/*"
          element={
            <PrivateRoute
              hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO, AUTHORITIES.COMPTABLE]}
            >
              <Tuteur />
            </PrivateRoute>
          }
        />
        <Route
          path="prof/*"
          element={
            <PrivateRoute
              hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO, AUTHORITIES.COMPTABLE]}
            >
              <Prof />
            </PrivateRoute>
          }
        />
        <Route
          path="recu/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.COMPTABLE]}>
              <Recu />
            </PrivateRoute>
          }
        />
        <Route
          path="quiz/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Quiz />
            </PrivateRoute>
          }
        />
        <Route
          path="galerie/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Galerie />
            </PrivateRoute>
          }
        />
        <Route
          path="ressource/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Ressource />
            </PrivateRoute>
          }
        />
        <Route
          path="tresorerie"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.COMPTABLE]}>
              <Tresorerie />
            </PrivateRoute>
          }
        />
        <Route
          path="payeeimpayee"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.COMPTABLE]}>
              <PayeeImpayee />
            </PrivateRoute>
          }
        />
        <Route
          path="garderie"
          element={
            <PrivateRoute
              hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO, AUTHORITIES.ASSISTANT]}
            >
              <Garderie />
            </PrivateRoute>
          }
        />
        <Route
          path="historique"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Historique />
            </PrivateRoute>
          }
        />
        <Route
          path="screen"
          element={
            <PrivateRoute
              hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO, AUTHORITIES.ASSISTANT]}
            >
              <Screen />
            </PrivateRoute>
          }
        />
        <Route
          path="devoirSurv/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <DevoirSurv />
            </PrivateRoute>
          }
        />
        <Route
          path="devoirSurv/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <DevoirNote />
            </PrivateRoute>
          }
        />
        <Route
          path="notes/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <Notes />
            </PrivateRoute>
          }
        />
        <Route
          path="audit-log/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <AuditLog />
            </PrivateRoute>
          }
        />
        <Route
          path="cahier_transmission/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PEDAGO, AUTHORITIES.ASSIST_PEDAGO]}>
              <CahierTransmission />
            </PrivateRoute>
          }
        />
        <Route
          path="page-mobile"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <PageMobile />
            </PrivateRoute>
          }
        />

        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
