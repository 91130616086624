import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { IScreen } from 'app/shared/model/screen.model';
import { getScreens, showScreen } from 'app/entities/garderie/garderie.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { GoLoading } from 'app/shared/loading';
import { Alert, Button, Space, Table } from 'antd';
import { PlayCircleOutlined } from '@mui/icons-material';
import CreateScreenModal from 'app/modules/modals/CreateScreenModal';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Show from 'app/entities/garderie/ui/Show';
const Screen = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const screensList = useAppSelector(state => state.garderie.entities) as IScreen[];
  const loadScreens = useAppSelector(state => state.garderie.loadScreens);
  const [notification, setNotification] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentScreen, setCurrentScreen] = useState<IScreen | null>(null);

  const handleCloseModal = () => {
    setCurrentScreen(null);
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setCurrentScreen(null);
    setShowModal(true);
  };

  const getAllEntities = () => {
    dispatch(getScreens({}));
  };

  useEffect(() => {
    getAllEntities();
  }, []);

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Nombre élève par screen',
      dataIndex: 'numEleve',
    },
    {
      title: 'Time Slide',
      dataIndex: 'timeSlide',
    },
    {
      title: 'Niveau',
      dataIndex: ['niveau', 'id'],
      render: (text: number, record: IScreen) => (record.niveau ? <Link to={`/niveau/${record.niveau.id}`}>{record.niveau.id}</Link> : ''),
    },
    {
      title: 'Groupe',
      dataIndex: ['groupe', 'id'],
      render: (text: number, record: IScreen) =>
        record.groupe ? <Link to={`/groupe/${record?.groupe?.id}`}>{record?.groupe?.id}</Link> : '',
    },
    {
      title: 'Actions',
      render: (text, record) => {
        return (
          <Space>
            <Button
              icon={<PlayCircleOutlined style={{ fontSize: '22px', color: 'green' }} />}
              size="middle"
              onClick={() => {
                dispatch(showScreen());
                navigate(`/screen/${record.id}`);
              }}
            >
              Play
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div style={{ height: '80vh', width: '68rem' }}>
      <h2 id="screen-heading" data-cy="ScreenHeading">
        Screens
        {notification}
        <div className="d-flex justify-content-end">
          <Button onClick={handleOpenModal} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Créer un nouveau Screen
          </Button>
        </div>
      </h2>
      <div className="table-responsive mt-4">
        <CreateScreenModal screen={currentScreen} show={showModal} handleClose={handleCloseModal} />
        {loadScreens ? (
          <GoLoading loading={loadScreens} />
        ) : screensList && screensList.length > 0 ? (
          <Table columns={columns} dataSource={screensList} rowKey="id" loading={loadScreens} pagination={false} />
        ) : (
          !loadScreens && <Alert message="Aucun Screen trouvé" type="warning" showIcon />
        )}
      </div>
    </div>
  );
};
export default Screen;
