import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Badge, Button, Col, DatePicker, List, Pagination, Row, Select, Space, Typography } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { byteSize, getPaginationState, openFile } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './ressource.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import dayjs from 'dayjs';
import { GoLoading } from 'app/shared/loading';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faEye, faPencilAlt, faSort, faSortDown, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getNiveauEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { IRessource } from 'app/shared/model/ressource.model';
import { downloadFile } from 'app/shared/util/utilitaire';

const { Option } = Select;

export const Ressource = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const [selectedEleve, setSelectedEleve] = useState('');
  const [selectedDateDebut, setSelectedDateDebut] = useState(null);
  const [selectedDateFin, setSelectedDateFin] = useState(null);
  const [selectedNiveau, setSelectedNiveau] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedTitre, setSelectedTitre] = useState(null);

  const ressourceList = useAppSelector(state => state.ressource.entities);
  const loading = useAppSelector(state => state.ressource.loading);
  const totalItems = useAppSelector(state => state.ressource.totalItems);
  const eleves = useAppSelector(state => state.eleve.entities);
  const niveaux = useAppSelector(state => state.niveau.entities);
  const groupes = useAppSelector(state => state.groupe.entities);
  const { Text, Title } = Typography;

  const getAllEntities = () => {
    const withRealAnneeScolaire = false;

    const queryParams = [];

    if (selectedEleve) {
      queryParams.push(`eleveId.equals=${selectedEleve}`);
    }

    if (selectedTitre) {
      queryParams.push(`code.equals=${selectedTitre}`);
    }

    if (selectedNiveau) {
      queryParams.push(`niveauId.equals=${selectedNiveau}`);
    }

    if (selectedGroupe) {
      queryParams.push(`groupeId.equals=${selectedGroupe}`);
    }

    if (selectedDateDebut) {
      const formattedDateDebut = selectedDateDebut.format('YYYY-MM-DD');
      queryParams.push(`dateDebut.greaterThanOrEqual=${formattedDateDebut}`);
    }

    if (selectedDateFin) {
      const formattedDateFin = selectedDateFin.format('YYYY-MM-DD');
      queryParams.push(`dateFin.lessThanOrEqual=${formattedDateFin}`);
    }

    queryParams.push(`withRealAnneeScolaire=${withRealAnneeScolaire}`);

    const query = queryParams.join('&');
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: query,
      }),
    );
  };

  useEffect(() => {
    dispatch(getEleves({}));
    dispatch(getNiveaux({}));
    dispatch(getGroupes({}));
  }, []);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    selectedEleve,
    selectedTitre,
    selectedNiveau,
    selectedGroupe,
    selectedDateDebut,
    selectedDateFin,
    paginationState,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage: any) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const handleEleveChange = value => {
    setSelectedEleve(value);
  };

  const columns = [
    {
      title: (
        <span onClick={sort('id')}>
          Code <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
        </span>
      ),
      dataIndex: 'id',
      sorter: true,
      render: text => (
        <Button type="link" onClick={() => navigate(`/ressource/${text}`)}>
          {text}
        </Button>
      ),
    },
    {
      title: (
        <span onClick={sort('date')}>
          Date <FontAwesomeIcon icon={getSortIconByFieldName('date')} />
        </span>
      ),
      dataIndex: 'date',
      sorter: true,
      render: date => (date ? dayjs(date).format(APP_LOCAL_DATE_FORMAT) : null),
    },
    {
      title: 'Fichier',
      dataIndex: 'fichier',
      render: (fichier, record) => (
        <div>
          {record.fichierContentType && fichier ? <a onClick={() => openFile(record.fichierContentType, fichier)}>Ouvrir &nbsp;</a> : null}
          {fichier && record.fichierContentType ? (
            <span>
              {record.fichierContentType}, {byteSize(fichier)}
            </span>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Groupe',
      dataIndex: 'groupe',
      render: groupe => <span>{groupe ? groupe.nom : ''}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Link to={`/ressource/${record.id}`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faEye} />}>
              Voir
            </Button>
          </Link>
          <Link to={`/ressource/${record.id}/edit`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faPencilAlt} />} style={{ color: 'green' }}>
              Editer
            </Button>
          </Link>
          <Link to={`/ressource/${record.id}/delete`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faTrash} />} danger>
              Supprimer
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="ressource-heading" data-cy="RessourceHeading">
        Ressources
      </h2>
      <div className="d-flex justify-content-end mb-3 ">
        <Button icon={<SyncOutlined spin={loading} />} style={{ marginRight: 4 }} onClick={handleSyncList} disabled={loading}>
          Actualiser la liste
        </Button>
        <Link to={'/ressource/new'}>
          <Button type="primary" icon={<PlusOutlined />}>
            Créer un nouveau Ressource
          </Button>
        </Link>
      </div>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Code"
            onChange={setSelectedTitre}
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {ressourceList.map(ressource => (
              <Option key={ressource.id} value={ressource.id}>
                #{ressource.id}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            showSearch
            // value={selectedEleve}
            onChange={handleEleveChange}
            placeholder="élève"
            style={{ width: '100%', fontWeight: 'lighter' }}
            allowClear
            filterOption={(input, option) => {
              const text = option?.label || option?.children;
              return text?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {eleves.map(eleve => (
              <Option key={eleve.id} value={eleve.id}>
                {eleve.nom} {eleve.prenom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Niveau"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedNiveau}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {niveaux.map(niveau => (
              <Option key={niveau.id} value={niveau.id}>
                {niveau.libelle}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Groupe"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedGroupe}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {groupes.map(groupe => (
              <Option key={groupe.id} value={groupe.id}>
                {groupe.nom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Date de Début" onChange={setSelectedDateDebut} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Date de Fin" onChange={setSelectedDateFin} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>
      </Row>
      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : ressourceList && ressourceList.length > 0 ? (
          <List
            grid={{
              gutter: 16,
              xs: 1, // 1 column on extra small screens (<576px)
              sm: 1, // 1 column on small screens (≥576px)
              md: 2, // 2 columns on medium screens (≥768px)
              lg: 2, // 2 columns on large screens (≥992px)
              xl: 2, // 2 columns on extra large screens (≥1200px)
            }}
            dataSource={ressourceList}
            renderItem={(item: IRessource) => {
              const isDeadlinePassed = dayjs().isAfter(item?.date);
              return (
                <List.Item
                  key={item.id}
                  className="hoverable-list-item"
                  onClick={() => navigate(`/ressource/${item.id}`)}
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Badge
                        count={isDeadlinePassed ? 'Passé' : 'En cours'}
                        style={{
                          backgroundColor: isDeadlinePassed ? '#f5222d' : '#52c41a',
                          color: '#fff',
                          marginBottom: '8px',
                        }}
                      />
                      <Title level={5}>{dayjs(item?.date).format('DD MMM, YYYY')}</Title>
                      <Text type="secondary">{dayjs(item.date).fromNow()}</Text>
                    </Col>
                    <Col>
                      <Space size="small">
                        <Button
                          onClick={e => {
                            const blobString = item?.fichier; // base64 encoded string
                            const fileType = item?.fichierContentType; // or any other mime type
                            const fileName = `${item?.fichier.substring(1, 5)}_content`;
                            e.stopPropagation();
                            downloadFile(blobString, fileType, fileName);
                          }}
                          type="link"
                          disabled={item?.fichier ? false : true}
                          icon={<FontAwesomeIcon icon={faDownload} />}
                        />

                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/ressource/${item.id}/edit`);
                          }}
                          type="link"
                          icon={<FontAwesomeIcon icon={faEdit} />}
                          style={{ color: 'green' }}
                        />
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/ressource/${item.id}/delete`);
                          }}
                          type="link"
                          danger
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        />
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Alert message="Aucun Ressource trouvé" type="warning" showIcon />
        )}
      </div>
      {!loading && ressourceList && ressourceList.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            total={totalItems}
            pageSize={paginationState.itemsPerPage}
            onChange={handlePagination}
            showSizeChanger
            onShowSizeChange={(current, size) => {
              setPaginationState({
                ...paginationState,
                itemsPerPage: size,
              });
              getAllEntities();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Ressource;
