import React, { useEffect, useState } from 'react';
import type { TransferProps } from 'antd';
import { Button, Modal, notification, Select, Transfer } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IEleve } from 'app/shared/model/eleve.model';
import { createEntities } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getEleves } from 'app/shared/reducers/reinscription.reducer';
import { getEntitiesTwo as getNiveau2, getNiveauEntities as getNiveau } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getAnnes } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import { INiveau } from 'app/shared/model/niveau.model';

interface RecordType {
  key: string | number;
  title: string;
  description: string;
}
const { Option } = Select;

const ReinscriptionModal = ({ show, handleClose }) => {
  const [mockData, setMockData] = useState<RecordType[]>([]);
  const [targetKeys, setTargetKeys] = useState<TransferProps['targetKeys']>([]);
  const eleveList = useAppSelector(state => state.reinscription.entities) as IEleve[];
  const niveauList1 = useAppSelector(state => state.niveau.entities) as INiveau[];
  const niveauList2 = useAppSelector(state => state.niveau.stateTwo.entities) as INiveau[];
  const anneeScolaireList = useAppSelector(state => state.anneeScolaire.entities) as IAnneeScolaire[];
  const dispatch = useAppDispatch();
  const [selectedAnnee, setSelectedAnnee] = useState<number | null>(null);
  const [selectedNiveau, setSelectedNiveau] = useState<number | null>(null);
  const [selectedNiveau2, setSelectedNiveau2] = useState<{ id: number; libelle: string } | null>(null);
  const [filteredNiveau, setFilteredNiveau] = useState<INiveau[]>([]);
  const checkboxValues = [
    'PC',
    'MS',
    'GS',
    'CP',
    'CE1',
    'CE2',
    'CM1',
    'CM2',
    'CE6',
    '1 APIC',
    '2 APIC',
    '3 APIC',
    'TCS',
    'TCS BIOF',
    'TCLSH BIOF',
    '1 BAC SE',
    '1 BAC SE BIOF',
    '1 BAC SECO',
    '1 BAC LSH BIOF',
    '2 BAC SP',
    '2 BAC SP BIOF',
    '2 BAC SMA',
    '2 BAC SMA BIOF',
    '2 BAC SM BIOF',
    '2 BAC SMB BIOF',
    '2 BAC SVT',
    '2 BAC TGC',
    '2 BAC SECO',
    'BAC LF',
    '2 BAC SHG',
    'FORMATION',
  ];
  const filteredAnne = anneeScolaireList.filter(annee => annee.isCurrent == false);
  useEffect(() => {
    if (selectedAnnee) {
      let query = `anneeScolaireId.equals=${selectedAnnee}&`;
      if (selectedNiveau2?.id) {
        query += `niveauId.equals=${selectedNiveau2.id}&`;
      }
      dispatch(
        getEleves({
          query: query.slice(0, -1),
        }),
      );
      dispatch(
        getNiveau({
          query: `anneeScolaireId.equals=${selectedAnnee}`,
        }),
      );
    }
    dispatch(getNiveau2({}));
    dispatch(getAnnes({}));
  }, [dispatch, selectedAnnee, selectedNiveau2]);

  useEffect(() => {
    // Transform eleveList to the format expected by Transfer component
    const tempMockData = eleveList.map(eleve => ({
      key: eleve.id?.toString() || '',
      title: eleve.nom || '',
      description: eleve.prenom || '',
    }));

    setMockData(tempMockData);
  }, [eleveList]);

  const handleChange: TransferProps['onChange'] = newTargetKeys => {
    setTargetKeys(newTargetKeys);
  };

  const handleOk = () => {
    if (selectedNiveau === null) {
      notification.warning({
        message: 'Choisir le nouveau niveau',
      });
      return;
    }
    // Dispatch createEntities action with targetKeys
    const eleve = targetKeys.map(key => eleveList.find(eleve => eleve.id?.toString() === key));
    const modifiedEntities = eleve.map(entity => ({
      ...entity,
      anneeScolaireId: entity.anneeScolaireId !== undefined ? selectedAnnee : null,
      niveauId: entity.niveauId !== undefined ? selectedNiveau : null,
    }));
    dispatch(createEntities(modifiedEntities));
    setTargetKeys([]);
    handleClose(); // Close modal after dispatching action
  };

  const renderFooter: TransferProps['footer'] = (_, info) => {
    return (
      <Button
        size="small"
        style={{ float: info?.direction === 'left' ? 'left' : 'right', margin: 5 }}
        onClick={() => dispatch(getEleves({}))}
      >
        Recharger
      </Button>
    );
  };

  const handleAnneeChange = (value: number) => {
    setSelectedAnnee(value);
  };

  const handleLeftNiveauChange = (value: number, option: any) => {
    setSelectedNiveau2({ id: value, libelle: option?.children });
  };

  const handleNiveauChange = (value: number) => {
    setSelectedNiveau(value);
  };

  useEffect(() => {
    if (selectedNiveau2 !== null) {
      console.log(selectedNiveau2.libelle);
      const selectedIndex = checkboxValues.indexOf(selectedNiveau2.libelle);
      const newFilteredNiveau = niveauList2.filter(niveau => checkboxValues.slice(selectedIndex).includes(niveau.libelle));
      setFilteredNiveau(newFilteredNiveau);
    } else {
      setFilteredNiveau(niveauList2);
    }
  }, [selectedNiveau2, niveauList2]);

  return (
    <Modal width={'80%'} title="Réinscription" open={show} onCancel={handleClose} onOk={handleOk}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div style={{ marginRight: 20 }}>
          <div style={{ marginRight: 20 }}>
            <Select
              value={selectedAnnee}
              onChange={handleAnneeChange}
              style={{ width: 200, color: 'grey', marginBottom: 20 }}
              placeholder="Annee Scolaire"
              allowClear
            >
              {filteredAnne.map(annee => (
                <Option key={annee.id} value={annee.id}>
                  {annee.periode}
                </Option>
              ))}
            </Select>
          </div>
          <Select
            value={selectedNiveau2?.id || undefined}
            onChange={handleLeftNiveauChange}
            style={{ width: 200, color: 'grey', marginBottom: 20 }}
            placeholder="Niveau ancien"
            allowClear
          >
            {niveauList1.map(niveau => (
              <Option key={niveau.id} value={niveau.id}>
                {niveau.libelle}
              </Option>
            ))}
          </Select>
          <Select
            value={selectedNiveau}
            onChange={handleNiveauChange}
            style={{ width: 200, color: 'grey', marginBottom: 20, marginLeft: 150 }}
            placeholder="Niveau a inscrire"
            allowClear
          >
            {filteredNiveau.map(niveau => (
              <Option key={niveau.id} value={niveau.id}>
                {niveau.libelle}
              </Option>
            ))}
          </Select>
          <Transfer
            dataSource={mockData}
            showSearch
            listStyle={{
              width: 250,
              height: 300,
            }}
            operations={['À droite', 'à gauche']}
            targetKeys={targetKeys}
            onChange={handleChange}
            render={item => `${item.title} ${item.description}`}
            footer={renderFooter}
          />
        </div>
        <div style={{ marginLeft: 20 }}></div>
      </div>
    </Modal>
  );
};

export default ReinscriptionModal;
