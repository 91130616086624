import React from 'react';
import { Badge, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { StatusPaiement } from 'app/shared/model/enumerations/Status-paiement.model';

const { Text } = Typography;

const ResponsivePaymentStatusBadge = ({ record }) => {
  const isMobile = useMediaQuery({ maxWidth: 1150 });

  const getBadgeProps = () => {
    if (record.rest === 0) {
      return { text: StatusPaiement.PAYE, color: 'green' };
    } else if (record.montant === 0) {
      return { text: StatusPaiement.IMPAYE, color: 'red' };
    } else {
      return { text: StatusPaiement.AVANCE, color: 'orange' };
    }
  };

  const { text, color } = getBadgeProps();

  if (isMobile) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Badge color={color} />
        <Text style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{record.rest}</Text>
      </div>
    );
  }

  return (
    <Badge.Ribbon text={text} color={color}>
      {record.rest}
    </Badge.Ribbon>
  );
};

export default ResponsivePaymentStatusBadge;
