import React, { useEffect, useState } from 'react';
import { Form, FormProps, GetProp, Input, Modal, Upload, UploadProps, InputNumber, Form as FormANT, DatePicker, Select } from 'antd';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col } from 'react-bootstrap';

import { addCheque, GetCheques, GetFichePaimentsGlobal, handleAmountCheque } from 'app/modules/tresorerie/fiche-paiement.reducer';

import { ICaisse } from 'app/shared/model/caisse.model';
import dayjs from 'dayjs';

const CreateCheckModal = ({ show, handleClose }) => {
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrlType, setImageUrlType] = useState<string>();
  const amountCheque = useAppSelector(state => state.fichePaiement.amountCheque);
  const cheques = useAppSelector(state => state.fichePaiement.cheques) as ICaisse[];
  const updateSuccess = useAppSelector(state => state.recu.updateSuccess);
  const cheque = useAppSelector(state => state.fichePaiement.cheque);
  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
  const onFinish: FormProps['onFinish'] = values => {
    const entity = {
      ...values,
      file: imageUrl ? imageUrl.replace('data:image/jpeg;base64,', '') : imageUrl,
      fileContentType: imageUrlType,
      dateDeRemise: values.dateDeRemise,
    };

    dispatch(addCheque(entity));
    handleClose();
  };
  const handleSelectChange = value => {
    if (!value) return;
    let cheque: ICaisse = cheques.filter(cheque => cheque?.id == value)[0];
    const entity = {
      ...cheque,
      file: imageUrl ? imageUrl.replace('data:image/jpeg;base64,', '') : imageUrl,
      fileContentType: imageUrlType,
      dateDeRemise: cheque.date,
    };
    dispatch(addCheque(cheque));
    if (cheque?.file) {
      if (cheque.file.includes('data:image/jpeg;base64')) {
        setImageUrl(cheque.file.split(',')[1]);
      } else {
        setImageUrl(cheque.file);
      }
    }
    form.setFieldsValue({
      numCheque: cheque?.numCheque,
      montantCheque: cheque?.montantCheque,
      dateDeRemise: dayjs(cheque?.dateDeRemise),
    });
    console.log(cheque);
  };
  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {};

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Charger chèque</div>
    </button>
  );

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = info => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as FileType, url => {
        setImageUrl(url.split(',')[1]);
        setImageUrlType(info.file.type);
      });
    }
  };

  const handleChangeForm = (changedValues, allValues) => {
    if (changedValues?.montantCheque) {
      let montantCheque = changedValues.montantCheque;
      dispatch(handleAmountCheque(montantCheque));
    }
  };

  useEffect(() => {
    dispatch(GetCheques());
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      montantCheque: amountCheque,
    });
  }, [amountCheque]);

  useEffect(() => {
    if (updateSuccess) {
      form.resetFields();
    }
  }, [updateSuccess]);

  console.log(cheque);
  return (
    <Modal width={'40%'} title="Créer un chèque" open={show} onOk={() => form.submit()} onCancel={handleClose}>
      <Select
        allowClear={true}
        onChange={handleSelectChange}
        showSearch
        placeholder="Cheque"
        style={{ width: '100%', fontWeight: 'lighter', marginBottom: 10 }}
        filterOption={(input, option) => option.children.toString().includes(input)}
      >
        {cheques.map((cheque: ICaisse) => (
          <Option key={cheque.id} value={cheque.id}>
            {cheque?.numCheque}
          </Option>
        ))}
      </Select>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleChangeForm}
        autoComplete="off"
      >
        <Form.Item hasFeedback>
          <Upload
            customRequest={({ onSuccess }) => onSuccess('ok')}
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChange}
          >
            {imageUrl ? <img src={`data:image/jpeg;base64,${imageUrl}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
          {imageUrlType}
        </Form.Item>
        <Col>
          <Form.Item name="numCheque">
            <Input placeholder="Numero de Chèque" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="montantCheque">
            <InputNumber placeholder="Montant de Chèque" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="dateDeRemise">
            <DatePicker placeholder="Date De Remise" style={{ width: '100%' }} type="date" />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item name="observation">
            <Input.TextArea placeholder="Observation" />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default CreateCheckModal;
