import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { DeleteOutlined, EditOutlined, EyeOutlined, FileExcelFilled, LogoutOutlined } from '@ant-design/icons';

import { Badge, Button, Card, Image, Input, Select, Spin, Tag } from 'antd';
import { getEntities, updateDepart } from './eleve.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getNiveauEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { IEleve } from 'app/shared/model/eleve.model';
import { IGroupe } from 'app/shared/model/groupe.model';
import { isNotEmpty } from 'app/shared/util/utilitaire';
import { Option } from 'antd/es/mentions';
import { debounce } from 'lodash';
import ReinscriptionModal from 'app/modules/modals/ReinscriptionModal';
import './styleEleve.scss';
import { CSVLink } from 'react-csv';

const { Search } = Input;

const gridStyle: React.CSSProperties = {
  backgroundColor: 'pink',
  padding: '5px',
  margin: '1px',
  borderRadius: '8px',
};

const blueStyle: React.CSSProperties = {
  backgroundColor: 'lightblue',
  padding: '5px',
  margin: '1px',
  borderRadius: '8px',
};

export const Eleve = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const eleveList = useAppSelector(state => state.eleve.entities) as IEleve[];
  const loading = useAppSelector(state => state.eleve.loading);
  const totalItems = useAppSelector(state => state.eleve.totalItems);

  const niveaux = useAppSelector(state => state.niveau.entities);
  const groupes = useAppSelector(state => state.groupe.entities);

  const [search, setSearch] = useState('');
  const [eleves, setEleves] = useState([]);
  const [selected, setSelected] = useState('');
  const [selectedNiveau, setSelectedNiveau] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedNiveauId, setSelectedNiveauId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [filterGroupes, setFilterGroupes] = useState([]);
  const isInitialMount = useRef(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedEleve, setSelectedEleve] = useState<any>({});
  const [showDepart, setShowDepart] = useState(false);
  const [ficheDepart, setFicheDepart] = useState({ idEleve: '', nomEleve: '', raisonDepart: '', dateDepart: '', eleve: {} });

  const [showReinscriptionModal, setShowReinscriptionModal] = useState(false); // State for reinscription modal
  const handleShowReinscription = () => setShowReinscriptionModal(true); // Show reinscription modal
  const handleCloseReinscription = () => setShowReinscriptionModal(false); // Close reinscription modal

  const [error, setError] = useState(false);

  useEffect(() => {
    if (loading) {
    } else {
      setEleves(eleveList);
    }
  }, [loading]);
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Nom', key: 'nom' },
    { label: 'Prenom', key: 'prenom' },
    { label: 'Date de Naissance', key: 'dateNaissance' },
    { label: 'Lieu de Naissance', key: 'lieuNaissance' },
    { label: 'Code Massar', key: 'codeMassar' },
    { label: 'Sexe', key: 'sexe' },
    { label: 'Observation', key: 'observation' },
    { label: 'Cin', key: 'cin' },
    { label: 'Niveau', key: 'niveau' },
    { label: 'Groupe', key: 'groupe' },
  ];

  const csvReport = {
    data: eleveList.map(eleve => ({
      id: eleve.id,
      nom: eleve.nom,
      prenom: eleve.prenom,
      dateNaissance: eleve.dateNaissance,
      lieuNaissance: eleve.lieuNaissance,
      codeMassar: eleve.codeMassar,
      sexe: eleve.sexe,
      observation: eleve.observation,
      cin: eleve.cin,
      niveau: eleve.groupe?.niveau?.libelle || '',
      groupe: eleve.groupe?.nom || '',
    })),
    headers: headers,
    filename: `eleves_${new Date().toISOString()}.csv`,
  };
  const getAllEntities = (search: string) => {
    dispatch(getNiveaux({}));
    dispatch(getGroupes({}));
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: isNotEmpty(search) ? 'search.equals=' + search : null,
        niveauId: selectedNiveauId,
        groupeId: selectedGroupId,
      }),
    );
  };

  useEffect(() => {
    getAllEntities(search);
  }, [search, selectedGroupId]);

  useEffect(() => {
    if (selectedNiveau) {
      // Filtrer les groupes en fonction du niveau sélectionné
      const filtered = groupes.filter((groupe: IGroupe) => groupe.niveau.id === selectedNiveauId);
      setFilterGroupes(filtered);
    } else {
      setFilterGroupes([]);
    }
  }, [selectedNiveau]);

  const sortEntities = () => {
    getAllEntities('');
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const onSearch = (value, _e, info) => {
    if (isNotEmpty(value)) {
      getAllEntities(value);
    } else {
      sortEntities();
    }
  };
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleSelectNiveau = eventKey => {
    if (eventKey === 'all') {
      setSelectedNiveau(null);
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: isNotEmpty(search) ? 'search.equals=' + search : null,
        }),
      );
    } else {
      const selectedNiveau = JSON.parse(eventKey);
      setSelectedNiveau(selectedNiveau.libelle);
      setSelectedNiveauId(selectedNiveau.id);
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: isNotEmpty(search) ? 'search.equals=' + search : null,
          niveauId: selectedNiveau.id,
          groupeId: selectedGroupId,
        }),
      );
    }
  };

  const handleSelectGroup = eventKey => {
    const selectedGroup = JSON.parse(eventKey);
    setSelectedGroupe(selectedGroup.nom);
    setSelectedGroupId(selectedGroup.id);
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: isNotEmpty(search) ? 'search.equals=' + search : null,
        niveauId: selectedNiveau.id,
        groupeId: selectedGroup.id,
      }),
    );
  };

  const searching: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  const dropdown: React.CSSProperties = {
    padding: '6px',
    borderRadius: '7px',
    margin: '0 10px',
    backgroundColor: 'grey',
  };

  const debouncedSearch = debounce(value => {
    setSearch(value);
  }, 700);

  const handleSearchChange = e => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (search === '') {
      if (selected) {
      } else {
      }
    } else {
      const filteredEleve = eleveList.filter(eleve => {
        if (
          eleve.nom.toLowerCase() === search.toLowerCase() ||
          eleve.prenom.toLowerCase() === search.toLowerCase() ||
          eleve.prenom.toLowerCase() + ' ' + eleve.nom.toLowerCase() === search.toLowerCase() ||
          eleve.nom.toLowerCase() + ' ' + eleve.prenom.toLowerCase() === search.toLowerCase() ||
          search === eleve.codeMassar
        ) {
          return eleve;
        }
      });
      setEleves(filteredEleve);
    }
  };
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (!selected) {
        alert('you must submit for searchinf');
      } else {
      }
    }
  }, [selected]);
  const handlePopup = eleve => {
    setShowDepart(true);
    setSelectedEleve(eleve);
    setFicheDepart({ idEleve: eleve.id, nomEleve: eleve.prenom + ' ' + eleve.nom, raisonDepart: '', dateDepart: '', eleve: eleve });
  };
  const handleFiche = e => {
    e.preventDefault();
    if (!ficheDepart.idEleve || !ficheDepart.raisonDepart || !ficheDepart.dateDepart) {
      setError(true);
      // Clear error message after 5 seconds
      setTimeout(() => setError(false), 3000);
      return;
    } else {
      dispatch(
        updateDepart({
          id: ficheDepart.idEleve,
          raisonDepart: ficheDepart.raisonDepart,
          dateDepart: ficheDepart.dateDepart,
        }),
      );

      setError(false);
      setShowDepart(false);
    }
  };
  const handleInputChange = event => {
    const { name, value } = event.target;

    // Update only the specific element while keeping others the same
    setFicheDepart(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const images = {
    male: '/content/images/male-child-image.png',
    female: '/content/images/female-child-image.jpg',
  };

  return (
    <div>
      <h2 id="eleve-heading" data-cy="EleveHeading">
        <Tag bordered={false} className={'capitalize'} style={{ padding: 15 }} color="processing">
          Eléves
        </Tag>
        <div className="button-container d-flex justify-content-end flex-wrap">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Actualiser la liste
          </Button>
          <Link to="/eleve/new">
            <Button type="primary">
              <FontAwesomeIcon icon="plus" />
              &nbsp; Créer un nouveau Eléve
            </Button>
          </Link>
          <Button className="me-2" color="info" style={{ marginLeft: '10px' }} onClick={handleShowReinscription}>
            réinscription
          </Button>
          <div className="d-flex align-items-center">
            <CSVLink {...csvReport} style={{ textDecoration: 'none' }}>
              <Button
                style={{
                  backgroundColor: 'green',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                type="primary"
              >
                <FileExcelFilled style={{ marginRight: 4 }} />
                Exporter Les eleves
              </Button>
            </CSVLink>
          </div>
        </div>
      </h2>
      {/* search for a specific student*/}
      <Form style={searching} onSubmit={handleSubmit} className="form-container">
        <Search
          type="search"
          placeholder="Nom,Prenom,Cin,Code massar"
          style={{ width: 'auto' }}
          allowClear
          enterButton="chercher"
          onChange={handleSearchChange}
          onSearch={onSearch}
        />

        <Select
          style={{ width: 200, color: 'grey', marginLeft: '8px' }}
          placeholder={`${selectedNiveau ? selectedNiveau : 'Niveaux'}`}
          onChange={handleSelectNiveau}
        >
          <Option key="all" value="all">
            tout
          </Option>
          {niveaux.map(niveau => {
            return (
              <Option key={niveau.id} value={JSON.stringify({ libelle: niveau.libelle, id: niveau.id })}>
                {niveau.libelle}
              </Option>
            );
          })}
        </Select>
        {selectedNiveau && filterGroupes.length !== 0 && (
          <Select style={{ width: 200, color: 'grey', marginLeft: '8px' }} placeholder="Groupes" onSelect={handleSelectGroup}>
            {filterGroupes?.map((groupe: IGroupe) => {
              return (
                <Option key={groupe.id.toString()} value={JSON.stringify({ nom: groupe.nom, id: groupe.id })}>
                  {groupe.nom}
                </Option>
              );
            })}
          </Select>
        )}
      </Form>

      <div className="table-responsive" style={{ overflow: 'hidden' }}>
        {loading ? (
          <Spin size="large" />
        ) : eleves && eleves.length > 0 ? (
          <Row style={{ margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {eleves.map((eleve, i) => (
              <Col style={{ minWidth: 280 }} className="mb-3 mt-3 mediaQuery" xs={12} sm={8} md={6} lg={3} key={i}>
                <Badge.Ribbon placement="start" text={eleve.codeMassar}>
                  <Card
                    styles={{
                      body: { padding: '15px', height: '190px', alignContent: 'center' },
                    }}
                    style={eleve.sexe == 'MALE' ? blueStyle : gridStyle}
                    key={i}
                    actions={[
                      <span onClick={() => handlePopup(eleve)}>
                        <LogoutOutlined style={{ color: '#FFA500' }} key="depart" />
                      </span>,
                      <span
                        onClick={() => {
                          navigate(`/eleve/${eleve.id}`);
                          handlePopup(eleve);
                        }}
                      >
                        <EyeOutlined style={{ color: '#FF4D4F' }} key="eye" />
                      </span>,
                      <span
                        onClick={() => {
                          navigate(
                            `/eleve/${eleve.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
                          );
                        }}
                      >
                        <EditOutlined style={{ color: '#1890FF' }} key="edit" />
                      </span>,

                      <span
                        onClick={() => {
                          navigate(
                            `/eleve/${eleve.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
                          );
                        }}
                      >
                        <DeleteOutlined style={{ color: '#1DA57A' }} key="delete" />
                      </span>,
                    ]}
                  >
                    <Row style={{ minHeight: '500px' }}>
                      <Col>
                        <Image
                          style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                          }}
                          src={
                            eleve.photo
                              ? `data:${eleve.photoContentType};base64,${eleve.photo}`
                              : eleve.sexe === 'MALE'
                                ? images.male
                                : images.female
                          }
                          preview={{
                            src: eleve.photo
                              ? `data:${eleve.photoContentType};base64,${eleve.photo}`
                              : eleve.sexe === 'MALE'
                                ? images.male
                                : images.female,
                          }}
                        />
                      </Col>
                      <Col>
                        <h6 style={{ fontWeight: 'bold' }}>{eleve.nom + ' ' + eleve.prenom}</h6>
                        <Tag style={{ padding: '5px' }} bordered={true} color="processing">
                          {eleve?.groupe?.nom}
                        </Tag>

                        <Tag style={{ padding: '5px' }} bordered={true} color="processing">
                          {eleve?.groupe?.niveau?.libelle}
                        </Tag>
                        <Tag style={{ padding: '5px' }} bordered={true} color="magenta">
                          {eleve?.codeMassar}
                        </Tag>
                      </Col>
                    </Row>
                  </Card>
                </Badge.Ribbon>
              </Col>
            ))}
          </Row>
        ) : (
          !loading && <div className="alert alert-warning mt-4">Aucun Eleve trouvé Dans l'annee scolaire courante</div>
        )}
      </div>
      {totalItems ? (
        <div className={eleveList && eleveList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <Modal show={showDepart} onHide={() => setShowDepart(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Départ</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleFiche}>
          <Modal.Body>
            {error && <p style={{ color: 'red', textAlign: 'center' }}>completer la fiche</p>}
            <Form.Group>
              <Form.Label>Nom d'élève</Form.Label>
              <Form.Control type="text" name="nomEleve" value={ficheDepart.nomEleve} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Raison de Départ</Form.Label>
              <Form.Control type="text" name="raisonDepart" value={ficheDepart.raisonDepart} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Date Départ</Form.Label>
              <Form.Control type="date" name="dateDepart" value={ficheDepart.dateDepart} onChange={handleInputChange} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowDepart(false)}>Fermer</Button>
            <Button type="primary" htmlType="submit">
              Sauvegarder
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ReinscriptionModal show={showReinscriptionModal} handleClose={handleCloseReinscription} />
    </div>
  );
};

export default Eleve;
