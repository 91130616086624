import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, INiveau } from 'app/shared/model/niveau.model';

// Define the state shape including stateTwo
interface NiveauState extends EntityState<INiveau> {
  stateTwo: EntityState<INiveau>;
}

const initialState: NiveauState = {
  loading: false,
  errorMessage: null,
  entities: [],
  stateTwo: {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
  },
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/niveaus';

// Actions

export const getNiveauEntities = createAsyncThunk('niveau/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${query}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<INiveau[]>(requestUrl);
});

export const getEntitiesTwo = createAsyncThunk('niveau/fetch_entity_list_two', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<INiveau[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'niveau/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<INiveau>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'niveau/create_entity',
  async (entity: INiveau, thunkAPI) => {
    const result = await axios.post<INiveau>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getNiveauEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const createEntities = createAsyncThunk(
  'niveau/create_entity',
  async (entities: string[], thunkAPI) => {
    const result = await axios.post<INiveau>(`${apiUrl}/multiple`, entities);
    thunkAPI.dispatch(getNiveauEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'niveau/update_entity',
  async (entity: INiveau, thunkAPI) => {
    const result = await axios.put<INiveau>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getNiveauEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'niveau/partial_update_entity',
  async (entity: INiveau, thunkAPI) => {
    const result = await axios.patch<INiveau>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getNiveauEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'niveau/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<INiveau>(requestUrl);
    thunkAPI.dispatch(getNiveauEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const NiveauSlice = createEntitySlice({
  name: 'niveau',
  initialState,
  reducers: {
    // No additional reducers needed at this point
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultValue;
      })
      .addMatcher(isFulfilled(getNiveauEntities), (state, action) => {
        const { data, headers } = action.payload;
        state.loading = false;
        state.entities = data;
        state.totalItems = parseInt(headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getEntitiesTwo), (state, action) => {
        const { data, headers } = action.payload;
        state.stateTwo.loading = false;
        state.stateTwo.entities = data;
        state.stateTwo.totalItems = parseInt(headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getNiveauEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(getEntitiesTwo), state => {
        state.stateTwo.errorMessage = null;
        state.stateTwo.updateSuccess = false;
        state.stateTwo.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = NiveauSlice.actions;

// Reducer
export default NiveauSlice.reducer;
