import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getNiveauEntities as getNiveaus } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getEmploiTemps } from 'app/entities/emploi-temps/emploi-temps.reducer';
import { getEntities as getSalles } from 'app/entities/salle/salle.reducer';
import { getEntities as getMatieres } from 'app/entities/matiere/matiere.reducer';
import { createEntity, getEntity, reset, updateEntity } from './groupe.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapIdList } from 'app/shared/util/entity-utils';

const { Title } = Typography;
const { Option } = Select;

const GroupeUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const niveaus = useAppSelector(state => state.niveau.entities);
  const emploiTemps = useAppSelector(state => state.emploiTemps.entities);
  const salles = useAppSelector(state => state.salle.entities);
  const matieres = useAppSelector(state => state.matiere.entities);
  const groupeEntity = useAppSelector(state => state.groupe.entity);
  const loading = useAppSelector(state => state.groupe.loading);
  const updating = useAppSelector(state => state.groupe.updating);
  const updateSuccess = useAppSelector(state => state.groupe.updateSuccess);

  const handleClose = () => {
    navigate('/groupe' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getNiveaus({}));
    dispatch(getEmploiTemps({}));
    dispatch(getSalles({}));
    dispatch(getMatieres({}));
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.capaciteGroupe !== undefined && typeof values.capaciteGroupe !== 'number') {
      values.capaciteGroupe = Number(values.capaciteGroupe);
    }

    const entity = {
      ...groupeEntity,
      ...values,
      niveau: niveaus.find(it => it.id.toString() === values.niveau?.toString()),
      emploisTemps: emploiTemps.find(it => it.id.toString() === values.emploisTemps?.toString()),
      salles: values.salles ? values.salles.map(id => ({ id })) : [],
      matieres: values.matieres ? mapIdList(values.matieres) : null,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...groupeEntity,
          niveau: groupeEntity?.niveau?.id,
          emploisTemps: groupeEntity?.emploisTemps?.id,
          salles: groupeEntity?.salles?.map(e => e.id.toString()),
          matieres: groupeEntity?.matieres?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col span={12}>
          <Title level={2}>{isNew ? 'Créer un Groupe' : 'Éditer un Groupe'}</Title>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col span={12}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form initialValues={defaultValues()} onFinish={saveEntity} layout="vertical">
              {!isNew && (
                <Form.Item name="id" label="Numéro" rules={[{ required: true, message: 'Le numéro est requis' }]}>
                  <Input disabled />
                </Form.Item>
              )}
              <Form.Item name="nom" label="Nom" rules={[{ required: true, message: 'Le nom est requis' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="code" label="Code">
                <Input />
              </Form.Item>
              <Form.Item name="capaciteGroupe" label="Capacité Groupe">
                <Input type="number" />
              </Form.Item>
              <Form.Item name="niveau" label="Niveau" rules={[{ required: true, message: 'Le niveau est requis' }]}>
                <Select>
                  {niveaus.map(otherEntity => (
                    <Option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.libelle}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="emploisTemps" label="Emplois Temps" rules={[{ required: true, message: 'L emploi du temps est requis' }]}>
                <Select>
                  {emploiTemps.map(otherEntity => (
                    <Option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.nom}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="salles" label="Salles">
                <Select mode="multiple">
                  {salles.map(otherEntity => (
                    <Option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.numero}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="matieres" label="Matieres">
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Sélectionner des matières"
                  optionFilterProp="label"
                  filterOption={(input, option) => {
                    return option?.label?.toString().toLowerCase().includes(input.toLowerCase());
                  }}
                  options={matieres.map(matiere => ({
                    value: matiere.id,
                    label: matiere.nom,
                  }))}
                />
              </Form.Item>

              <Form.Item>
                <Button type="default" onClick={() => navigate('/groupe')}>
                  Retour
                </Button>
                <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }} loading={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Sauvegarder
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GroupeUpdate;
