import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Form, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEntities, getNiveauEntities } from 'app/entities/niveau/niveau.reducer';
import { INiveau } from 'app/shared/model/niveau.model';
import '../../entities/gestion-notes/styling.scss';

const { Option } = Select;

const CreateNiveauScolaireModal = ({ show, handleClose }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const saveEntity = () => {
    dispatch(createEntities(selectedValues));
    setSelectedValues([]);
    handleClose();
  };

  const niveauxExistants = useAppSelector(state => state.niveau.entities).map(niveau => niveau.libelle);

  useEffect(() => {
    dispatch(getNiveauEntities({}));
  }, [dispatch]);

  const niveauList: INiveau[] = useAppSelector(state => state.niveau.entities);
  const checkboxValues = [
    'PS',
    'MS',
    'GS',
    'CP',
    'CE1',
    'CE2',
    'CM1',
    'CM2',
    'CE6',
    '1 APIC',
    '2 APIC',
    '3 APIC',
    'TCS',
    'TCS BIOF',
    'TCLSH BIOF',
    '1 BAC SE',
    '1 BAC SE BIOF',
    '1 BAC SECO',
    '1BAC LSH BIOF',
    '2 BAC SP',
    '2 BAC SP BIOF',
    '2 BAC SMA',
    '2 BAC SMA BIOF',
    '2 BAC SM BIOF',
    '2 BAC SMB BIOF',
    '2 BAC SVT',
    '2 BAC TGC',
    '2 BAC SECO',
    'BAC LF',
    '2 BAC SHG',
    'FORMATION',
  ];

  const filteredCheckboxValues = checkboxValues.filter(option => !niveauxExistants.includes(option));

  const handleSelectChange = values => {
    setSelectedValues(values);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Créer un nouveau Niveau</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onFinish={saveEntity}>
          <Form.Item
            name="niveaux"
            label="Niveau(x)"
            rules={[
              {
                required: true,
                message: 'Veuillez sélectionner au moins un niveau',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Sélectionnez le(s) niveau(x)"
              allowClear
              showSearch
              filterOption={(input, option) => option?.children.toString().includes(input.toUpperCase())}
              onChange={handleSelectChange}
              style={{ width: '100%', fontWeight: 'lighter', marginBottom: 10, height: 40 }}
              value={selectedValues}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              {filteredCheckboxValues.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Modal.Footer>
            <Button onClick={handleClose} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button type="primary" htmlType="submit" id="save-entity" data-cy="entityCreateSaveButton">
              <FontAwesomeIcon icon="save" />
              &nbsp; Sauvegarder
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNiveauScolaireModal;
