import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Col, DatePicker, List, Modal, Pagination, Row, Select, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateTransmissionModal from 'app/entities/cahier-transmission/CreateTransmissionModal';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ITransmissionResponse } from 'app/shared/model/cahier_transmission_model/transmission.model';
import {
  clearTransmission,
  getOneTransmission,
  getTransmissions,
  reset,
  updateStatusForm,
} from 'app/entities/cahier-transmission/transmission.reducer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { GoLoading } from 'app/shared/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState } from 'react-jhipster';
import { SORT } from 'app/shared/util/pagination.constants';
import { getNiveauEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getProfs } from 'app/entities/prof/prof.reducer';
import { IProf } from 'app/shared/model/prof.model';
import { useMediaQuery } from 'react-responsive';

const CahierTransmission = () => {
  const { Option } = Select;
  dayjs.extend(relativeTime);
  dayjs.locale('fr');
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const { Title, Text } = Typography;
  const [paginationState, setPaginationState] = useState(getPaginationState(pageLocation, 6, 'id'));
  const transmissions = useAppSelector(state => state.transmission.entities) as ITransmissionResponse[];
  const loadTransmissions = useAppSelector(state => state.transmission.loadTransmissions);
  const updateTransmissionSuccess = useAppSelector(state => state.transmission.updateTransmissionSuccess);
  const addTransmissionSuccess = useAppSelector(state => state.transmission.addTransmissionSuccess);
  const totalItems = useAppSelector(state => state.transmission.totalItems) as number;
  const statusFormAdd = useAppSelector(state => state.transmission.statusFormAdd);

  const [selectedEleve, setSelectedEleve] = useState('');
  const [selectedDateDebut, setSelectedDateDebut] = useState(null);
  const [selectedDateFin, setSelectedDateFin] = useState(null);
  const [selectedNiveau, setSelectedNiveau] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedProf, setSelectedProf] = useState(null);

  const niveaux = useAppSelector(state => state.niveau.entities);
  const groupes = useAppSelector(state => state.groupe.entities);
  const eleves = useAppSelector(state => state.eleve.entities);
  const profs = useAppSelector(state => state.prof.entities) as IProf[];

  const handlePaginationChange = (currentPage: number) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

    navigate(`${pageLocation.pathname}?page=${currentPage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  useEffect(() => {
    dispatch(getNiveaux({}));
    dispatch(getGroupes({}));
    dispatch(getEleves({}));
    dispatch(getProfs({}));
  }, []);

  useEffect(() => {
    const withRealAnneeScolaire = false;

    const queryParams = [];

    if (selectedEleve) {
      queryParams.push(`eleveId.equals=${selectedEleve}`);
    }

    if (selectedNiveau) {
      queryParams.push(`niveauId.equals=${selectedNiveau}`);
    }

    if (selectedGroupe) {
      queryParams.push(`groupeId.equals=${selectedGroupe}`);
    }

    if (selectedProf) {
      queryParams.push(`profId.equals=${selectedProf}`);
    }

    if (selectedDateDebut) {
      const formattedDateDebut = selectedDateDebut.format('YYYY-MM-DD');
      queryParams.push(`dateDebut.greaterThanOrEqual=${formattedDateDebut}`);
    }

    if (selectedDateFin) {
      const formattedDateFin = selectedDateFin.format('YYYY-MM-DD');
      queryParams.push(`dateFin.lessThanOrEqual=${formattedDateFin}`);
    }

    queryParams.push(`withRealAnneeScolaire=${withRealAnneeScolaire}`);

    const query = queryParams.join('&');

    dispatch(
      getTransmissions({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      }),
    );
  }, [selectedEleve, selectedProf, selectedNiveau, selectedGroupe, selectedDateDebut, selectedDateFin, paginationState]);

  const sortEntities = () => {
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }

    dispatch(
      getTransmissions({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `withRealAnneeScolaire=${false}`,
      }),
    );
  };

  useEffect(() => {
    if (updateTransmissionSuccess || addTransmissionSuccess) {
      dispatch(
        getTransmissions({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `withRealAnneeScolaire=${false}`,
        }),
      );
    }
  }, [updateTransmissionSuccess, addTransmissionSuccess]);
  const handleSyncList = () => {
    sortEntities();
  };
  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <h2 id="absence-eleve-heading" className="mt-5" data-cy="AbsenceEleveHeading">
        Suivi pédagogique
      </h2>
      <div className="d-flex justify-content-end mt-5">
        <Space direction={isMobile ? 'vertical' : 'horizontal'} style={!isMobile ? { width: 'fit-content' } : { width: '100%' }}>
          <Button
            className="me-2"
            icon={<FontAwesomeIcon icon={faSync} spin={loadTransmissions} />}
            onClick={handleSyncList}
            loading={loadTransmissions}
            style={isMobile ? { width: '100%' } : {}}
          >
            Actualiser la liste
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch(updateStatusForm(true));
              dispatch(clearTransmission());
              toggleModal();
            }}
            style={isMobile ? { width: '100%' } : {}}
          >
            Créer un suivi pédagogique
          </Button>
          <Button
            style={{
              backgroundColor: 'green',
              ...(isMobile && { width: '100%' }),
            }}
            type="primary"
            onClick={() => {
              dispatch(reset());
              navigate(`validate`);
            }}
          >
            Valider suivi pédagogique
          </Button>
        </Space>
      </div>
      <Row gutter={[16, 16]} justify="center" style={{ paddingTop: 10 }}>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Select Prof"
            onChange={setSelectedProf}
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {profs.map(prof => (
              <Option key={prof.id} value={prof.id}>
                {prof?.firtName} {prof?.lastName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            showSearch
            // value={selectedEleve}
            onChange={setSelectedEleve}
            placeholder="Select élève"
            style={{ width: '100%', fontWeight: 'lighter' }}
            allowClear
            filterOption={(input, option) => {
              const text = option?.label || option?.children;
              return text?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {eleves.map(eleve => (
              <Option key={eleve.id} value={eleve.id}>
                {eleve.nom} {eleve.prenom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Select Niveau"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedNiveau}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {niveaux.map(niveau => (
              <Option key={niveau.id} value={niveau.id}>
                {niveau.libelle}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Select Groupe"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedGroupe}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {groupes.map(groupe => (
              <Option key={groupe.id} value={groupe.id}>
                {groupe.nom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Select Date de Début" onChange={setSelectedDateDebut} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Select Date de Fin" onChange={setSelectedDateFin} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>
      </Row>
      <div className="mt-4">
        {loadTransmissions ? (
          <GoLoading loading={loadTransmissions} />
        ) : transmissions && transmissions.length > 0 ? (
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 3,
            }}
            dataSource={transmissions}
            renderItem={(item: ITransmissionResponse) => {
              return (
                <List.Item
                  key={item.id}
                  className="hoverable-list-item"
                  onClick={() => navigate(`/cahier_transmission/${item.id}`)}
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Badge
                        count={item?.prof ? 'Prof' : 'Administration'}
                        style={{
                          backgroundColor: item?.prof ? '#52c41a' : '#f5222d',
                          color: '#fff',
                          marginBottom: '8px',
                        }}
                      />
                      <Title level={5}>{dayjs(item?.createdAt).format('DD MMM, YYYY')}</Title>
                      <Text strong>
                        <Text style={{ color: '#1DA57A', marginRight: 6 }}>
                          {' '}
                          {item.eleve?.prenom} {item.eleve?.nom}
                        </Text>
                      </Text>
                      <Text type="secondary">{dayjs(item?.createdAt).fromNow()}</Text>
                      <p style={{ marginTop: '8px' }}>
                        {item?.description?.length > 35 ? `${item.description.substring(0, 35)}...` : item.description}
                      </p>
                    </Col>
                    <Col>
                      <Space size="small">
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            dispatch(updateStatusForm(false));
                            toggleModal();
                            dispatch(getOneTransmission(item?.id));
                          }}
                          type="link"
                          icon={<FontAwesomeIcon icon={faEdit} />}
                          style={{ color: 'green' }}
                        />
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/cahier_transmission/${item.id}/delete`);
                          }}
                          type="link"
                          danger
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        />
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Alert message="Aucun Suivi pédagogique trouvé" type="warning" />
        )}
      </div>
      {!loadTransmissions && totalItems && transmissions && transmissions.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePaginationChange}
            total={totalItems}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
      <Modal
        title={statusFormAdd ? 'Créer un suivi pédagogique' : 'Modifier un suivi pédagogique'}
        visible={modal}
        onCancel={toggleModal}
        footer={null}
      >
        <CreateTransmissionModal toggleModal={toggleModal} />
      </Modal>
    </>
  );
};
export default CahierTransmission;
