import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Alert, Form, InputNumber, Modal } from 'antd';
import { GoLoading } from 'app/shared/loading';
import { createCoefficient, getCoefficient } from 'app/entities/matiere/coefficient.reducer';

const AddCoefficientModal = ({ show, handleClose, matiere, sousMatiere }) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const niveauList = useAppSelector(state => state.coefficient.entities);
  const loading = useAppSelector(state => state.coefficient.loading);

  const getAllEntities = () => {
    dispatch(
      getCoefficient({
        id: sousMatiere?.id === undefined ? matiere?.id : sousMatiere?.id,
        isMatiere: sousMatiere?.id === undefined,
        sort: `asc`,
      }),
    );
  };

  useEffect(() => {
    if (matiere?.id || sousMatiere?.id) getAllEntities();
  }, [matiere?.id, sousMatiere?.id]);

  // A fixed list of objects with initial values

  const onFinish = values => {
    console.log('Submitted Values:', values);
    console.log(JSON.stringify(values));

    dispatch(
      createCoefficient({
        entity: values,
        id: sousMatiere?.id === undefined ? matiere?.id : sousMatiere?.id,
        isMatiere: sousMatiere?.id === undefined,
      }),
    );

    handleClose();
  };
  niveauList.forEach(item => {
    console.log(JSON.stringify(item));

    form.setFieldValue([item.idNiveau], item.coefficient);
  });

  const title = `Créer un coefficient : ${matiere?.nom ?? 'Matière inconnue'} - ${sousMatiere?.nom ?? ''}`;

  return (
    <Modal destroyOnClose={true} title={title} open={show} onOk={() => form.submit()} onCancel={handleClose}>
      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : niveauList && niveauList.length > 0 ? (
          <Form form={form} name="fixed_list_form" onFinish={onFinish} autoComplete="off">
            {niveauList.map((item, index) => (
              <>
                <Form.Item
                  initialValue={item.coefficient}
                  key={item.idNiveau}
                  name={item.idNiveau} // Use an array index for naming
                  label={item.nomNiveau}
                  rules={[{ required: true, message: 'Veuillez entrer un nombre !' }]}
                >
                  <InputNumber placeholder={` ${item.coefficient}`} />
                </Form.Item>
              </>
            ))}
          </Form>
        ) : (
          <Alert message="Aucun niveau trouvé" type="warning" showIcon />
        )}
      </div>
    </Modal>
  );
};

export default AddCoefficientModal;
