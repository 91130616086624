import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Badge, Button, Col, List, message, Popconfirm, Row, Space, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faPlus, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntities } from './groupe.reducer';
import { getPaginationState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { GoLoading } from 'app/shared/loading';
import { IGroupe } from 'app/shared/model/groupe.model';

export const Groupe = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [showModal, setShowModal] = useState(false);
  const [currentGroupe, setCurrentGroupe] = useState(null);
  const [currentId, setCurrentId] = useState(null);

  const groupeList = useAppSelector(state => state.groupe.entities);
  const loading = useAppSelector(state => state.groupe.loading);
  const totalItems = useAppSelector(state => state.groupe.totalItems);
  const { Text, Title } = Typography;

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter.field,
      order: sorter.order === 'ascend' ? ASC : DESC,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(currentId));
    message.success('Supprimé');
  };

  const cancelDelete = () => {
    message.error('Suppression annulée');
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: (text, record) => (
        <Button type="link" href={`/groupe/${record.id}`}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: 'Capacité Groupe',
      dataIndex: 'capaciteGroupe',
      sorter: true,
    },
    {
      title: 'Niveau',
      dataIndex: 'niveau',
      render: text => (text ? <Link to={`/niveau/${text.id}`}>{text.id}</Link> : ''),
    },
    {
      title: 'Emplois Temps',
      dataIndex: 'emploisTemps',
      render: text => (text ? <Link to={`/emploi-temps/${text.id}`}>{text.id}</Link> : ''),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" href={`/groupe/${record.id}`} icon={<FontAwesomeIcon icon={faEye} />} style={{ color: 'blue' }} />
          <Button
            type="link"
            onClick={() => {
              navigate(
                `/groupe/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
              );
            }}
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            style={{ color: 'green' }}
          />
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer cet élément ?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" onClick={() => setCurrentId(record.id)} icon={<FontAwesomeIcon icon={faTrash} />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="groupe-heading" data-cy="GroupeHeading">
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="magenta">
          Groupes
        </Tag>

        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
          <Button type="primary" onClick={() => navigate('/groupe/new')}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Créer un nouveau Groupe
          </Button>
        </div>
      </h2>
      {/*<div className="table-responsive">
        <Table
          columns={columns}
          dataSource={groupeList}
          rowKey="id"
          pagination={{
            current: paginationState.activePage,
            pageSize: paginationState.itemsPerPage,
            total: totalItems,
            showSizeChanger: true,
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>*/}
      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : groupeList && groupeList.length > 0 ? (
          <List
            grid={{
              gutter: 16,
              xs: 1, // 1 column on extra small screens (<576px)
              sm: 2, // 1 column on small screens (≥576px)
              md: 2, // 2 columns on medium screens (≥768px)
              lg: 3, // 2 columns on large screens (≥992px)
              xl: 3, // 2 columns on extra large screens (≥1200px)
            }}
            dataSource={groupeList}
            renderItem={(item: IGroupe) => {
              return (
                <List.Item
                  key={item.id}
                  className="hoverable-list-item"
                  onClick={() => navigate(`/groupe/${item.id}`)}
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Badge
                        count={item.id}
                        style={{
                          color: '#fff',
                          marginBottom: '8px',
                        }}
                      />
                      <Title level={5}>{item.nom}</Title>
                      <Title level={5} type="secondary">
                        {item.niveau.libelle}
                      </Title>
                    </Col>
                    <Col>
                      <Space size="small">
                        <Button
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/groupe/${item.id}`);
                          }}
                          icon={<FontAwesomeIcon icon={faEye} />}
                          style={{ color: 'blue' }}
                        />
                        <Button
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            navigate(
                              `/groupe/${item.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
                            );
                          }}
                          icon={<FontAwesomeIcon icon={faPencilAlt} />}
                          style={{ color: 'green' }}
                        />
                        <Button
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            setCurrentId(item.id);
                            navigate(`/groupe/${item.id}/delete`);
                          }}
                          icon={<FontAwesomeIcon icon={faTrash} />}
                          danger
                        />
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Alert message="Aucun niveau trouvé" type="warning" showIcon />
        )}
      </div>
      {totalItems ? (
        <div className={groupeList && groupeList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Groupe;
