import React, { useEffect } from 'react';
import { Carousel } from 'antd';
import Cards from 'app/entities/garderie/ui/Cards';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getGarderies, getOneScreen, showScreen, updateGarderies } from 'app/entities/garderie/garderie.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { IScreen } from 'app/shared/model/screen.model';
import { IGarderie } from 'app/shared/model/garderie.model';
import usePusher from 'app/shared/util/pusherClient';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

const Show = () => {
  const chunkArray = (array: IGarderie[], chunkSize: number): IGarderie[][] => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  const getIdFromUrl = () => {
    const url = window.location.pathname;
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  const id = getIdFromUrl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadOneScreen = useAppSelector(state => state.garderie.loadOneScreen);
  const screen: IScreen = useAppSelector(state => state.garderie.entity);
  const garderies = useAppSelector(state => state.garderie.garderies) as IGarderie[];
  const numEleve = screen.numEleve || 1;
  const chunkedGarderies = chunkArray(garderies, numEleve);

  const pusherInstance = usePusher();

  useEffect(() => {
    if (pusherInstance) {
      const channel = pusherInstance.subscribe('garderie-channel');

      channel.bind('garderie-updated', data => {
        const garderieNotification = JSON.parse(data);
        dispatch(getOneScreen(id));
        dispatch(getGarderies({}));
      });

      return () => {
        pusherInstance.unsubscribe('garderie-channel');
      };
    }
  }, [dispatch, pusherInstance]);

  useEffect(() => {
    dispatch(getOneScreen(id));
    dispatch(getGarderies({}));
  }, []);
  const handle = useFullScreenHandle();
  const contentStyle: React.CSSProperties = {
    height: '100vh',
    color: '#fff',
    lineHeight: '100vh',
    textAlign: 'center',
    background: '#364d79',
  };

  const overlayStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1000, // Adjust this value if needed
    overflow: 'hidden',
  };

  const data1 = {
    title: 'Ahmed Ben',
    phone: '06 51 35 87 37',
    image: 'https://img.freepik.com/free-photo/view-child-hair-salon_23-2150462483.jpg',
    description: ' En route ',
    tuteur: 'Mouad Ben',
    enfant: 'Ahmed Ben',
    niveau: 'PC',
    groupe: 'G6',
    isNew: true,
  };

  const data2 = {
    title: 'Karim Saad',
    phone: '06 51 35 87 38',
    image: 'https://img.freepik.com/free-photo/view-child-hair-salon_23-2150462484.jpg',
    description: 'Arrive',
    tuteur: 'Oussama Ben',
    enfant: 'Yassine Lamouadden',
    niveau: 'CE1',
    groupe: 'G4',
    isNew: true,
  };

  return (
    <div
      style={overlayStyle}
      onClick={() => {
        dispatch(showScreen());
        navigate(`/screen`);
      }}
    >
      <Carousel autoplay={true} autoplaySpeed={(screen?.timeSlide || 1) * 1000} style={{ width: '100%', height: '100%' }}>
        {chunkedGarderies.map((group, groupIndex) => (
          <div key={groupIndex}>
            <Cards data={group} numEleve={numEleve} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default Show;

// <Carousel autoplay={true} autoplaySpeed={screen?.timeSlide * 1000} style={{ width: '100%', height: '100%' }}>
//   <Cards data={data1} numEleve={screen?.numEleve} />
//   <Cards data={data2} numEleve={screen?.numEleve} />
//   <Cards data={data1} numEleve={screen?.numEleve} />
//   <Cards data={data1} numEleve={screen?.numEleve} />
// </Carousel>
