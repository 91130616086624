import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, reset } from './absence-eleve.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { Alert, Avatar, Button, Col, DatePicker, List, Modal, Pagination, Row, Select, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import AbsenceEleveCreate from './absence-eleve-create';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { GoLoading } from 'app/shared/loading';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import useCurrentAnneeScolaire from 'app/shared/util/useCurrentAnneeScolaire';
import { IAbsenceEleve } from 'app/shared/model/absence-eleve.model';
import Box from '@mui/material/Box';
import { IProf } from 'app/shared/model/prof.model';
import { getNiveauEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getProfs } from 'app/entities/prof/prof.reducer';
import { useMediaQuery } from 'react-responsive';

const { Option } = Select;

export const AbsenceEleve = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const [modal, setModal] = useState(false);
  const absenceEleveList = useAppSelector(state => state.absenceEleve.entities) as IAbsenceEleve[];
  const loading = useAppSelector(state => state.absenceEleve.loading);
  const totalItems = useAppSelector(state => state.eleve.totalItems);

  const [selectedEleve, setSelectedEleve] = useState('');
  const [selectedDateDebut, setSelectedDateDebut] = useState(dayjs().startOf('month'));
  const [selectedDateFin, setSelectedDateFin] = useState(dayjs().endOf('month'));
  const [selectedNiveau, setSelectedNiveau] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedProf, setSelectedProf] = useState(null);

  const niveaux = useAppSelector(state => state.niveau.entities);
  const groupes = useAppSelector(state => state.groupe.entities);
  const eleves = useAppSelector(state => state.eleve.entities);
  const profs = useAppSelector(state => state.prof.entities) as IProf[];

  const { periode } = useCurrentAnneeScolaire();

  useEffect(() => {
    dispatch(getNiveaux({}));
    dispatch(getGroupes({}));
    dispatch(getEleves({ query: `paged=${false}` }));
    dispatch(getProfs({}));
    getAllEntities();
  }, []);

  const getAllEntities = () => {
    const withRealAnneeScolaire = false;

    const queryParams = [];

    if (selectedEleve) {
      queryParams.push(`eleveId.equals=${selectedEleve}`);
    }

    if (selectedNiveau) {
      queryParams.push(`niveauId.equals=${selectedNiveau}`);
    }

    if (selectedGroupe) {
      queryParams.push(`groupeId.equals=${selectedGroupe}`);
    }

    if (selectedProf) {
      queryParams.push(`profId.equals=${selectedProf}`);
    }

    if (selectedDateDebut) {
      const formattedDateDebut = selectedDateDebut.format('YYYY-MM-DD');
      queryParams.push(`dateDebut.greaterThanOrEqual=${formattedDateDebut}`);
    }

    if (selectedDateFin) {
      const formattedDateFin = selectedDateFin.format('YYYY-MM-DD');
      queryParams.push(`dateFin.lessThanOrEqual=${formattedDateFin}`);
    }

    queryParams.push(`withRealAnneeScolaire=${withRealAnneeScolaire}`);

    const query = queryParams.join('&');

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    selectedEleve,
    selectedProf,
    selectedNiveau,
    selectedGroupe,
    selectedDateDebut,
    selectedDateFin,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const toggleModal = () => setModal(!modal);

  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate(); // Get the last day of the month
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="red">
        Absence Élèves
      </Tag>
      <div className="d-flex justify-content-end mt-5">
        <Space direction={isMobile ? 'vertical' : 'horizontal'} style={!isMobile ? { width: 'fit-content' } : { width: '100%' }}>
          <Button
            type="primary"
            icon={<SyncOutlined spin={loading} />}
            onClick={handleSyncList}
            disabled={loading}
            style={isMobile ? { width: '100%' } : {}}
          >
            Actualiser la liste
          </Button>
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleModal} style={isMobile ? { width: '100%' } : {}}>
            Créer une Absence
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: 'green',
              ...(isMobile && { width: '100%' }),
            }}
            onClick={() => {
              dispatch(reset());
              navigate(`validate`);
            }}
          >
            Valider les Absences
          </Button>
        </Space>
      </div>
      <Row gutter={[16, 16]} justify="center" style={{ paddingTop: 10 }}>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Select Prof"
            onChange={setSelectedProf}
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {profs.map(prof => (
              <Option key={prof.id} value={prof.id}>
                {prof?.firtName} {prof?.lastName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            showSearch
            // value={selectedEleve}
            onChange={setSelectedEleve}
            placeholder="Select élève"
            style={{ width: '100%', fontWeight: 'lighter' }}
            allowClear
            filterOption={(input, option) => {
              const text = option?.label || option?.children;
              return text?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {eleves.map(eleve => (
              <Option key={eleve.id} value={eleve.id}>
                {eleve.nom} {eleve.prenom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Select Niveau"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedNiveau}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {niveaux.map(niveau => (
              <Option key={niveau.id} value={niveau.id}>
                {niveau.libelle}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Select Groupe"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedGroupe}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {groupes.map(groupe => (
              <Option key={groupe.id} value={groupe.id}>
                {groupe.nom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <DatePicker
            placeholder="Select Date de Début"
            value={selectedDateDebut}
            onChange={setSelectedDateDebut}
            style={{ width: '100%', fontWeight: 'lighter' }}
          />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <DatePicker
            placeholder="Select Date de Fin"
            value={selectedDateFin}
            onChange={setSelectedDateFin}
            style={{ width: '100%', fontWeight: 'lighter' }}
          />
        </Col>
      </Row>
      <div className="table-responsive mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : absenceEleveList ? (
          absenceEleveList && absenceEleveList.length > 0 ? (
            <Box className="card-style--garderie">
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 3,
                }}
                dataSource={absenceEleveList}
                renderItem={(item: IAbsenceEleve, index) => (
                  <List.Item
                    className="hoverable-list-item"
                    onClick={() => {
                      navigate(`/absence-eleve/${item.id}`);
                    }}
                    style={{
                      padding: '20px',
                      borderRadius: '8px',
                      border: '1px solid #e8e8e8',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#fff',
                      marginBottom: '16px',
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingBottom: '10px',
                          width: '100%',
                        }}
                      >
                        <div>
                          <span style={{ fontSize: '12px', opacity: '40%' }}>
                            créer par{' '}
                            {item?.prof
                              ? `${item?.prof?.internalUser?.firstName} ${item?.prof?.internalUser?.lastName}`
                              : "l'administration"}
                          </span>
                        </div>
                      </div>
                      <List.Item.Meta
                        style={{ width: '100%' }}
                        avatar={<Avatar src={`data:image/jpeg;base64,${item.eleve?.photo}`} />}
                        title={
                          <div
                            style={{
                              display: 'flex',
                              fontSize: '16px',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column', color: '#000', textDecoration: 'none' }}>
                              {dayjs(item?.date).format('DD MMMM YYYY')}
                              <div style={{ fontSize: '16px' }}>
                                <span
                                  style={{
                                    color: 'green',
                                    fontWeight: 'bold',
                                    opacity: '60%',
                                  }}
                                >
                                  {item.eleve?.prenom} {item.eleve?.nom}
                                </span>
                              </div>
                              <span style={{ opacity: '60%', fontSize: '12px' }}>{item?.periode?.toLowerCase()}</span>
                            </div>
                            {/*<span style={{ marginLeft: '10px', opacity: '60%' }}>{item?.periode}</span>*/}
                            <Space size="small">
                              {/*<Button*/}
                              {/*  onClick={e => {*/}
                              {/*    e.stopPropagation();*/}
                              {/*    navigate(`/absence-eleve/${item.id}`);*/}
                              {/*  }}*/}
                              {/*  type="link"*/}
                              {/*  icon={<FontAwesomeIcon icon={faEdit} />}*/}
                              {/*  style={{ color: 'green' }}*/}
                              {/*/>*/}
                              <Button
                                onClick={e => {
                                  e.stopPropagation();
                                  navigate(`/absence-eleve/${item.id}/delete`);
                                }}
                                type="link"
                                danger
                                icon={<FontAwesomeIcon icon={faTrash} />}
                              />
                            </Space>
                          </div>
                        }
                        description={
                          <div
                            style={{
                              display: 'flex',
                              fontSize: '16px',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingTop: '14px',
                            }}
                          ></div>
                        }
                      />
                    </div>
                  </List.Item>
                )}
              />
            </Box>
          ) : (
            <Alert message="Aucun absence trouvé" type="warning" showIcon />
          )
        ) : (
          <Alert message="Veuillez sélectionner une année et un mois" type="info" showIcon />
        )}
      </div>
      {!loading && absenceEleveList && absenceEleveList.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePagination}
            total={totalItems}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
      <Modal title="Créer une Absence" visible={modal} onCancel={toggleModal} footer={null}>
        <AbsenceEleveCreate toggleModal={toggleModal} />
      </Modal>
    </>
  );
};

export default AbsenceEleve;
