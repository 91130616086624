import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Badge, Button, Col, List, Row, Space, Tag, Typography } from 'antd';
import { getPaginationState, JhiItemCount, JhiPagination, openFile, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faEdit,
  faEye,
  faPencilAlt,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faSync,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntities, reset } from './annonce.reducer';
import { GoLoading } from 'app/shared/loading';
import dayjs from 'dayjs';
import { downloadFile } from 'app/shared/util/utilitaire';
import { IAnnonce } from 'app/shared/model/annonce.model';

const { Text, Title } = Typography;

const Annonce = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [sorting, setSorting] = useState(false);

  const annonceList = useAppSelector(state => state.annonce.entities);
  const loading = useAppSelector(state => state.annonce.loading);
  const links = useAppSelector(state => state.annonce.links);
  const updateSuccess = useAppSelector(state => state.annonce.updateSuccess);
  const totalItems = useAppSelector(state => state.annonce.totalItems);

  const getAllEntities = () => {
    dispatch(reset());
    const withRealAnneeScolaire = false;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `withRealAnneeScolaire=${withRealAnneeScolaire}`,
      }),
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  return (
    <div>
      <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="orange">
        Annonces
      </Tag>
      <div className="d-flex justify-content-end">
        <Button className="me-2" icon={<FontAwesomeIcon icon={faSync} spin={loading} />} onClick={handleSyncList} disabled={loading}>
          Actualiser la liste
        </Button>
        <Link to="/annonce/new" id="jh-create-entity" data-cy="entityCreateButton">
          <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
            Créer un nouveau Annonce
          </Button>
        </Link>
      </div>
      <div className="mt-4">
        {loading && annonceList.length === 0 ? (
          <GoLoading loading={loading} />
        ) : annonceList && annonceList.length > 0 ? (
          <List
            grid={{
              gutter: 16,
              xs: 1, // 1 column on extra small screens (<576px)
              sm: 1, // 1 column on small screens (≥576px)
              md: 2, // 2 columns on medium screens (≥768px)
              lg: 2, // 2 columns on large screens (≥992px)
              xl: 2, // 2 columns on extra large screens (≥1200px)
            }}
            dataSource={annonceList}
            renderItem={(item: IAnnonce) => {
              const isDeadlinePassed = dayjs().isAfter(item?.dateEnvoi);
              return (
                <List.Item
                  key={item.id}
                  className="hoverable-list-item"
                  onClick={() => navigate(`/annonce/${item.id}`)}
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Badge
                        count={isDeadlinePassed ? 'Passé' : 'En cours'}
                        style={{
                          backgroundColor: isDeadlinePassed ? '#f5222d' : '#52c41a',
                          color: '#fff',
                          marginBottom: '8px',
                        }}
                      />
                      <Title level={5}>{dayjs(item?.dateEnvoi).format('DD MMM, YYYY')}</Title>
                      <Text
                        style={{ color: '#1DA57A' }}
                        strong
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <a target="_blank" href={item?.lien} style={{ color: '#1DA57A', marginRight: 6, textDecoration: 'none' }}>
                          {item?.lien}
                        </a>
                      </Text>
                      <Text type="secondary">{dayjs(item?.dateEnvoi).fromNow()}</Text>
                      <p style={{ marginTop: '8px' }}>
                        {item?.contenu?.length > 25 ? `${item.contenu.substring(0, 35)}...` : item.contenu}
                      </p>
                    </Col>
                    <Col>
                      <Space size="small">
                        <Button
                          onClick={e => {
                            const blobString = item?.image; // base64 encoded string
                            const fileType = item?.imageContentType; // or any other mime type
                            const fileName = `${item?.contenu}_content`;
                            e.stopPropagation();
                            downloadFile(blobString, fileType, fileName);
                          }}
                          type="link"
                          disabled={item?.image ? false : true}
                          icon={<FontAwesomeIcon icon={faDownload} />}
                        />

                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/annonce/${item.id}/edit`);
                          }}
                          type="link"
                          icon={<FontAwesomeIcon icon={faEdit} />}
                          style={{ color: 'green' }}
                        />
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/annonce/${item.id}/delete`);
                          }}
                          type="link"
                          danger
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        />
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          !loading && <Alert message="Aucun Annonce trouvé" type="warning" />
        )}
      </div>
      {totalItems ? (
        <div className={annonceList && annonceList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Annonce;
