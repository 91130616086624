import { IEmploiTempsLigne } from 'app/shared/model/emploi-temps-ligne.model';
import { IProf } from 'app/shared/model/prof.model';
import { IGroupe } from 'app/shared/model/groupe.model';
import { ISousMatiere } from 'app/shared/model/sous-matiere.model';

export interface IMatiere {
  id?: number;
  nom?: string | null;
  code?: string | null;
  emploisTempsLignes?: IEmploiTempsLigne | null;
  profs?: IProf[] | null;
  groupes?: IGroupe[] | null;
  sousMatieres?: ISousMatiere[] | null;
}

export const defaultValue: Readonly<IMatiere> = {};
