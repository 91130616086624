import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, ICoefficient } from 'app/shared/model/coefficient.model';

const initialState: EntityState<ICoefficient> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/matieres/coefficient';

// Actions
export const getCoefficient = createAsyncThunk('coefficient/fetch_entity_list', async ({ id, isMatiere, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?id=${id}&isMatiere=${isMatiere}&sort=${sort}`;
  return axios.get<ICoefficient[]>(requestUrl);
});

export const createCoefficient = createAsyncThunk(
  'coefficient/create_entity',
  async ({ entity, id, isMatiere, sort }: any) => {
    return await axios.post<ICoefficient>(`${apiUrl}?id=${id}&isMatiere=${isMatiere}&sort=${sort}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

// slice

export const CoefficientSlice = createEntitySlice({
  name: 'coefficient',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getCoefficient), (state, action) => {
        const { data, headers } = action.payload;
        console.info(data);
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })

      .addMatcher(isPending(getCoefficient), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = CoefficientSlice.actions;

// Reducer
export default CoefficientSlice.reducer;
