import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { defaultValue, ISousMatiere } from 'app/shared/model/sous-matiere.model';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';

const initialState: EntityState<ISousMatiere> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/sous-matieres';

// Actions

export const getSousMatiereList = createAsyncThunk(
  'sous-sous-matiere/fetch_entity_list',
  async ({ matiereId, page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${sort ? `page=${page}&matiereId=${matiereId}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<ISousMatiere[]>(requestUrl);
  },
);

export const getSousMatiere = createAsyncThunk(
  'sous-matiere/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISousMatiere>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createSousMatiere = createAsyncThunk(
  'sous-matiere/create_entity',
  async (entity: ISousMatiere, thunkAPI) => {
    const result = await axios.post<ISousMatiere>(`${apiUrl}?matiereId=${entity.matiereId}`, cleanEntity(entity));
    console.info({ matiereId: entity.matiereId });
    thunkAPI.dispatch(getSousMatiereList({ matiereId: entity.matiereId, sort: 'asc' }));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateSousMatiere = createAsyncThunk(
  'sous-matiere/update_entity',
  async (entity: ISousMatiere, thunkAPI) => {
    const result = await axios.put<ISousMatiere>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getSousMatiereList({ matiereId: entity.matiereId }));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateSousMatiere = createAsyncThunk(
  'sous-matiere/partial_update_entity',
  async (entity: ISousMatiere, thunkAPI) => {
    const result = await axios.patch<ISousMatiere>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getSousMatiereList({ matiereId: entity.matiereId, sort: 'asc' }));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteSousMatiere = createAsyncThunk(
  'sous-matiere/delete_entity',
  async ({ id, matiereId }: IQueryParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISousMatiere>(requestUrl);
    thunkAPI.dispatch(getSousMatiereList({ matiereId: matiereId, sort: 'asc' }));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const SousMatiereSlice = createEntitySlice({
  name: 'sous-matiere',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getSousMatiere.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteSousMatiere.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getSousMatiereList), (state, action) => {
        const { data, headers } = action.payload;
        console.info(data);
        console.info('xxxf');
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createSousMatiere, updateSousMatiere, partialUpdateSousMatiere), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getSousMatiere, getSousMatiere), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createSousMatiere, updateSousMatiere, partialUpdateSousMatiere, deleteSousMatiere), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = SousMatiereSlice.actions;

// Reducer
export default SousMatiereSlice.reducer;
