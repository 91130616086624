import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import {
  Badge,
  Button as Button2,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  GetProp,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Statistic,
  Tag,
  Upload,
  UploadProps,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getServiceEtablissementEntities } from 'app/entities/service-etablisement/service-etablisement.reducer';
import { getEntities as getTuteurs } from 'app/entities/tuteur/tuteur.reducer';
import { getNiveauEntities as getNiveaus } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getTarifs } from 'app/entities/tarif/tarif.reducer';
import { createEntity, getEntity, getPaiementEleve, reset, updateEntity } from './eleve.reducer';
import { ITuteur } from 'app/shared/model/tuteur.model';
import { IGroupe } from 'app/shared/model/groupe.model';
import { IEleve } from 'app/shared/model/eleve.model';
import { LoadingOutlined, PlusCircleFilled, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITarif } from 'app/shared/model/tarif.model';
import { INiveau } from 'app/shared/model/niveau.model';
import { GoLoading } from 'app/shared/loading';
import CreateTuteurModal from 'app/modules/modals/CreateTuteurModal';
import MoisEleveModal from 'app/modules/modals/MoisEleveModal';
import CreateServiceModal from 'app/modules/modals/CreateServiceModal';
import { Option } from 'antd/es/mentions';
import TextArea from 'antd/es/input/TextArea';

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

interface SelectedOption {
  label: string;
  value: number | string; // Adjust based on the actual type of 'value' in your options
}

export const EleveUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const groupes = useAppSelector(state => state.groupe.entities) as IGroupe[];
  const niveaus = useAppSelector(state => state.niveau.entities) as INiveau[];
  const tuteurs = useAppSelector(state => state.tuteur.entities) as ITuteur[];
  const customTuteur = useAppSelector(state => state.eleve.customEntite) as ITuteur;
  const allTuteurs = [...tuteurs, customTuteur];
  const preSelectedTuteur = customTuteur.id;
  const [selectedTuteurs, setSelectedTuteurs] = useState([]);
  const eleveEntity = useAppSelector(state => state.eleve.entity) as IEleve;
  let firstNiveau = eleveEntity?.groupe?.niveau?.id;
  const loading = useAppSelector(state => state.eleve.loading);
  const updateSuccess = useAppSelector(state => state.eleve.updateSuccess);
  const tarifList = useAppSelector(state => state.tarif.entities) as ITarif[];
  const [tarifs, setTarifs] = useState([] as ITarif[]);
  const [imageUrl, setImageUrl] = useState<string>();
  const selectedMois = useAppSelector(state => state.eleve.months);
  const upperCaseMonths = selectedMois.map(month => month.toUpperCase());
  const [imageUrlType, setImageUrlType] = useState<string>();
  const anneeScolaireId = useAppSelector(state => state.eleve.entity.anneeScolaireId);
  const [selectedNiveau, setNiveauSelected] = useState<string | number>();
  const [selectedGroupeId, setSelectedGroupId] = useState<string | number>();
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [form] = Form.useForm();
  const [showServices, setShowServices] = useState<boolean>(false);
  const filteredGroupeList = groupes.filter(groupe => {
    if (selectedNiveau) {
      return groupe.niveau && groupe.niveau.id == selectedNiveau;
    } else {
      return groupe.niveau && groupe.niveau.id == firstNiveau;
    }
  });
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const handleSelectNiveau = (value: string | number) => {
    if (!showServices) {
      setShowServices(true);
    }

    setNiveauSelected(value);
  };

  const handleSelectGroup = eventKey => {
    const selectedGroup = JSON.parse(eventKey);
    setSelectedGroupe(selectedGroup.nom);
    setSelectedGroupId(selectedGroup.id);
  };

  function mergeTarifs(tarifList: ITarif[], eleveTarifs: ITarif[] | null): ITarif[] {
    const tarifMap = new Map<number, ITarif>();

    tarifList.forEach(tarif => {
      if (tarif.id !== undefined) {
        tarifMap.set(tarif.serviceEtablisement.id, tarif);
      }
    });

    eleveTarifs?.forEach(tarif => {
      if (tarif.id !== undefined) {
        tarifMap.set(tarif.serviceEtablisement.id, tarif);
      }
    });

    return Array.from(tarifMap.values());
  }

  const handleServiceChange = (id: number, field: string, value: any) => {
    const newItems: ITarif[] = tarifs.map(tarif => (tarif.id === id ? { ...tarif, [field]: value } : tarif));
    setTarifs(newItems);

    // Update total amount
    setTotalAmount(
      newItems.reduce((sum, tarif) => {
        if (tarif.checked && tarif.serviceEtablisement.type == 'MENSUELLE') {
          return sum + (tarif.prix || 0);
        }
        return sum;
      }, 0),
    );
  };

  useEffect(() => {
    // Combine the selected tuteurs with the custom tuteur
    if (customTuteur?.id) {
      setSelectedTuteurs(prevTuteurs => {
        const customTuteurExists = prevTuteurs.some(tuteur => tuteur.value === customTuteur.id);
        if (!customTuteurExists) {
          return [...prevTuteurs, { value: customTuteur.id, label: `${customTuteur.internalUser.firstName}` }];
        }
        return prevTuteurs;
      });
    }
  }, [customTuteur]);

  useEffect(() => {
    if (customTuteur?.id) {
      form.setFieldsValue({ tuteurs: selectedTuteurs });
    }
  }, [selectedTuteurs, customTuteur]);

  useEffect(() => {
    if (!isNew && eleveEntity) {
      setSelectedTuteurs(
        eleveEntity?.tuteurs?.map(tuteur => ({
          value: tuteur.id,
          label: `${tuteur?.internalUser?.firstName}`,
        })),
      );
      form.setFieldsValue(defaultValues());
    }
  }, [eleveEntity, isNew, form]);

  useEffect(() => {
    if (selectedNiveau) {
      dispatch(getTarifs({ query: `niveauId.equals=${selectedNiveau}` }));
    }
  }, [selectedNiveau]);

  useEffect(() => {
    if (firstNiveau && !isNew) {
      dispatch(getTarifs({ query: `niveauId.equals=${firstNiveau}` }));
    }
  }, [firstNiveau]);

  const handleClose = () => {
    setTimeout(() => {
      navigate('/eleve' + location.search);
    }, 1500);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      setShowServices(true);
      dispatch(getEntity(id));
      dispatch(getPaiementEleve(id));
    }

    dispatch(getGroupes({}));
    dispatch(getServiceEtablissementEntities({}));
    dispatch(getTuteurs({ query: `&paged=false` }));
    dispatch(getNiveaus({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (eleveEntity && !isNew) {
      setImageUrlType(eleveEntity.photoContentType);
      if (eleveEntity?.tarifs) {
        const tarifsFirst = mergeTarifs(tarifList, eleveEntity?.tarifs);

        setTarifs(tarifsFirst);
        setTotalAmount(
          eleveEntity.tarifs.reduce((sum, tarif) => {
            if (tarif.checked && tarif.serviceEtablisement.type == 'MENSUELLE') {
              return sum + (tarif.prix || 0);
            }
            return sum;
          }, 0),
        );
      }
      if (eleveEntity.photo) {
        if (eleveEntity.photo.includes('data:image/jpeg;base64')) {
          setImageUrl(eleveEntity.photo.split(',')[1]);
        } else {
          setImageUrl(eleveEntity.photo);
        }
      }
    }
  }, [eleveEntity, tarifList]);

  useEffect(() => {
    if (eleveEntity && tarifList && selectedNiveau) {
      if (isNew) {
        const updatedTarifList = tarifList.map(tarif => ({
          ...tarif,
          checked: true,
        }));
        setTarifs(updatedTarifList);
        setTotalAmount(
          updatedTarifList.reduce((sum, tarif) => {
            if (tarif.checked && tarif.serviceEtablisement.type == 'MENSUELLE') {
              return sum + (tarif.prix || 0);
            }
            return sum;
          }, 0),
        );
      } else {
        setTarifs(tarifList);
      }
    }
  }, [eleveEntity, tarifList]);

  useEffect(() => {
    if (!isNew && selectedNiveau == firstNiveau) {
      const tarifsFirst = mergeTarifs(tarifList, eleveEntity?.tarifs);
      setTarifs(tarifsFirst);
    }
  }, [selectedNiveau, tarifList]);

  const updateServices = (tarif: ITarif) => {
    setTarifs([...tarifs, tarif]);
  };

  const onFinish = (values: any) => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    const fullName = values.nomComplet.trim();
    const names = fullName.split(' ');
    const nom = names.slice(1).join(' ');
    const prenom = names[0];

    const entity = {
      ...eleveEntity,
      ...values,
      nom: nom,
      prenom: prenom,
      mois: upperCaseMonths,
      photo: imageUrl ? imageUrl.replace('data:image/jpeg;base64,', '') : imageUrl,
      photoContentType: imageUrlType,
      internalUser: users.find(it => it.id.toString() === values.internalUser?.toString()),
      groupe: groupes.find(it => it.id.toString() === (selectedGroupeId ? selectedGroupeId.toString() : eleveEntity.groupe.id.toString())),
      tuteurs: values.tuteurs.map(item => {
        return { id: typeof item === 'object' ? item.value : item };
      }),
      tarifs: tarifs.filter(tarif => tarif.checked === true),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : ({
          sexe: 'MALE',
          ...eleveEntity,
          nomComplet: eleveEntity.prenom + ' ' + eleveEntity.nom,
          dateNaissance: eleveEntity.dateNaissance ? dayjs(eleveEntity.dateNaissance) : undefined,
          internalUser: eleveEntity?.internalUser?.id,
          groupe: eleveEntity?.groupe?.nom,
          devoirs: eleveEntity?.devoirs?.map(e => e.id.toString()),
          controles: eleveEntity?.controles?.map(e => e.id.toString()),
          tuteurs: eleveEntity?.tuteurs?.map(e => e.id),
          resources: eleveEntity?.resources?.map(e => e.id.toString()),
          galeries: eleveEntity?.galeries?.map(e => e.id.toString()),
          niveau: eleveEntity?.groupe?.niveau?.libelle,
        } as any);

  const handleChange: UploadProps['onChange'] = info => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as FileType, url => {
        setImageUrl(url.split(',')[1]);
        setImageUrlType(info.file.type);
      });
    }
  };

  const handleChangeService = (index, field, value) => {
    const newItems = [...tarifs];
    newItems[index][field] = value;
    setTarifs(newItems);
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Charger une image</div>
    </button>
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMoisEleve, setIsModalOpenMoisEleve] = useState(false);

  const [isModalOpenService, setIsModalOpenService] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalSerivce = () => {
    setIsModalOpenService(true);
  };
  const showModalMoisEleve = () => {
    setIsModalOpenMoisEleve(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpenMoisEleve(false);
    setIsModalOpenService(false);
  };
  const validateFullName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Veuillez saisir votre nom complet !'));
    }
    const names = value.trim().split(' ');
    if (names.length < 2) {
      return Promise.reject(new Error('Veuillez saisir votre nom et votre prénom !'));
    }
    return Promise.resolve();
  };

  // Function to disable future dates in DatePicker
  const disabledDate = (current: any) => {
    // Disable dates after today
    return current && current > dayjs().endOf('day');
  };

  return (
    <div>
      <Row className="justify-content-center">
        <CreateTuteurModal show={isModalOpen} handleClose={handleOk}></CreateTuteurModal>
        <MoisEleveModal show={isModalOpenMoisEleve} handleClose={handleOk}></MoisEleveModal>
        <CreateServiceModal updateService={updateServices} show={isModalOpenService} handleClose={handleOk}></CreateServiceModal>
        <Col md="12">
          {loading ? (
            <GoLoading loading={loading}></GoLoading>
          ) : (
            <>
              <Tag bordered={false} className={'capitalize'} style={{ padding: 15 }} color="processing">
                {!isNew ? <>Editer un élève</> : <>Créer un élève</>}
              </Tag>

              <Form form={form} name="validate_other" {...formItemLayout} onFinish={onFinish} initialValues={defaultValues()}>
                <Row className="flex-row">
                  <Col md={4}>
                    <Label>Nom Complet</Label>
                    <Form.Item
                      name="nomComplet"
                      rules={[{ required: true, message: '' }, { validator: validateFullName }]}
                      validateTrigger="onSubmit"
                    >
                      <Input />
                    </Form.Item>
                    <Label>Code Massar</Label>
                    <Form.Item name="codeMassar">
                      <Input />
                    </Form.Item>
                    <Label>Date Naissance</Label>
                    <Form.Item
                      name="dateNaissance"
                      rules={[{ required: true, message: 'Veuillez sélectionner votre date de naissance !' }]}
                    >
                      <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Label>Gender</Label>
                    <Form.Item name="sexe">
                      <Radio.Group>
                        <Radio value="MALE">
                          <span style={{ color: '#3DC2EC' }}>Garçon</span>
                        </Radio>
                        <Radio value="FEMALE">
                          <span style={{ color: '#E6B9A6' }}>Fille</span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Label>Observation</Label>
                    <Form.Item name="observation">
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col md={2}>
                    <Form.Item hasFeedback name="image">
                      <Upload
                        customRequest={({ onSuccess }) => onSuccess('ok')}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={handleChange}
                      >
                        {imageUrl ? (
                          <img src={`data:image/jpeg;base64,${imageUrl}`} alt="avatar" style={{ width: '100%' }} />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      {imageUrlType}
                    </Form.Item>
                  </Col>
                </Row>

                <Divider></Divider>
                <Row className="justify-content-center">
                  <Col md={3}>
                    <Form.Item label="Niveau" name="niveau" rules={[{ required: true, message: 'sélectionner votre niveau!' }]}>
                      <Select disabled={eleveEntity?.beginPaiement} allowClear onChange={handleSelectNiveau}>
                        {niveaus.map(otherEntity => (
                          <Option value={otherEntity.id.toString()} key={otherEntity.id.toString()}>
                            {otherEntity.libelle}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={3}>
                    <Form.Item label="Groupe" name="groupe" rules={[{ required: true, message: 'sélectionner votre groupe!' }]}>
                      <Select allowClear onSelect={handleSelectGroup}>
                        {filteredGroupeList.map(otherEntity => (
                          <Option value={JSON.stringify({ nom: otherEntity.nom, id: otherEntity.id })} key={otherEntity.id.toString()}>
                            {otherEntity.nom}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={5}>
                    <Form.Item
                      label="Tuteur"
                      name="tuteurs"
                      rules={[
                        {
                          required: true,
                          message: 'Sélectionnez au moins un tuteur!',
                          type: 'array',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        value={selectedTuteurs}
                        onChange={value => setSelectedTuteurs(value)}
                        allowClear
                        showSearch
                        disabled={eleveEntity?.beginPaiement}
                        placeholder="Sélectionner un tuteur"
                        optionFilterProp="label"
                        filterOption={(input, option) => {
                          return option?.label?.toString().toLowerCase().includes(input.toLowerCase());
                        }}
                        options={tuteurs?.map(tuteur => ({
                          value: tuteur.id,
                          label: `${tuteur?.internalUser?.firstName}`,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={1}>
                    <Button2 type="primary" icon={<PlusCircleFilled />} onClick={showModal} />
                  </Col>
                </Row>
                <Divider></Divider>
                {showServices && (
                  <Row className="justify-content-center">
                    <Col md={4}>
                      <Card title="Services Annuels" size="small">
                        {tarifs
                          .filter(tarif => tarif?.serviceEtablisement?.type === 'ANNUELLE')
                          .map(tarif => (
                            <Badge.Ribbon key={tarif.id} text={tarif?.serviceEtablisement?.type} color="red">
                              <Row>
                                <Col md={2}>
                                  <Checkbox
                                    checked={tarif.checked || false}
                                    disabled={tarif.beginPaiement}
                                    onChange={e => handleServiceChange(tarif.id!, 'checked', e.target.checked)}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Badge color="info">{tarif?.serviceEtablisement?.libelle}</Badge>
                                </Col>
                                <Col md={6}>
                                  <InputNumber
                                    disabled={tarif.beginPaiement}
                                    value={tarif.prix || 0}
                                    onChange={value => handleServiceChange(tarif.id!, 'prix', value)}
                                  />
                                </Col>
                              </Row>
                            </Badge.Ribbon>
                          ))}
                      </Card>
                    </Col>

                    <Button2 type="primary" icon={<PlusCircleFilled />} onClick={showModalSerivce} />
                    <Col md={4}>
                      <Card title="Services Mensuels" size="small">
                        {tarifs
                          .filter(tarif => tarif?.serviceEtablisement?.type === 'MENSUELLE')
                          .map(tarif => (
                            <Badge.Ribbon key={tarif.id} text={tarif?.serviceEtablisement?.type} color="red">
                              <Row>
                                <Col md={2}>
                                  <Checkbox
                                    checked={tarif.checked || false}
                                    onChange={e => handleServiceChange(tarif.id!, 'checked', e.target.checked)}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Badge color="info">{tarif?.serviceEtablisement?.libelle}</Badge>
                                </Col>
                                <Col md={6}>
                                  <InputNumber value={tarif.prix || 0} onChange={value => handleServiceChange(tarif.id!, 'prix', value)} />
                                </Col>
                              </Row>
                            </Badge.Ribbon>
                          ))}
                      </Card>
                    </Col>

                    <Col span={24} style={{ marginTop: '16px', textAlign: 'center' }}>
                      <Card>
                        <Statistic
                          title="Le montant total mensuel"
                          value={totalAmount}
                          precision={2}
                          valueStyle={{ color: '#3f8600' }}
                          suffix="DH"
                        />
                      </Card>
                    </Col>
                  </Row>
                )}

                {/* /!*value={checkedList} onChange={onChange}*/}

                <Divider></Divider>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Space>
                    <Button2 type="primary" icon={<PlusCircleFilled />} onClick={showModalMoisEleve}>
                      Mois eleve
                    </Button2>

                    <Button2 type="primary" htmlType="submit">
                      Valider
                    </Button2>
                    <Link to={'/eleve'}>
                      <Button2 type="link" id="cancel-save" data-cy="entityCreateCancelButton" color="info">
                        <FontAwesomeIcon icon="arrow-left" />
                        &nbsp;
                        <span className="d-none d-md-inline">Retour</span>
                      </Button2>
                    </Link>
                  </Space>
                </Form.Item>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EleveUpdate;
