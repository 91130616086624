import dayjs from 'dayjs';
import { IEtatServiceMois } from 'app/shared/model/etat-service-mois.model';
import { ModeReglement } from 'app/shared/model/enumerations/mode-reglement.model';

export interface ICaisse {
  id?: number;
  statut?: boolean | null;
  montant?: number | null;
  montantSortie?: number | null;
  montantCheque?: number | null;
  type?: keyof typeof ModeReglement | null;
  date?: dayjs.Dayjs | null;
  dateDeRemise?: dayjs.Dayjs | null;
  numCheque?: string | null;
  photoChequeContentType?: string | null;
  photoCheque?: string | null;
  fileContentType?: string | null;
  file?: string | null;
  etatServiceMois?: IEtatServiceMois | null;
}

export const defaultValue: Readonly<ICaisse> = {
  statut: false,
};
