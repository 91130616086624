import './chart.scss';

import React from 'react';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Tabs } from 'antd';
import ChartGestionScolaire from 'app/modules/chart/chartGestionScolaire';
import Tresotory from 'app/modules/chart/tresotory';
import Computable from 'app/modules/chart/computable';
import { useAppSelector } from 'app/config/store';
import { AUTHORITIES } from 'app/config/constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ArcElement, Legend);

export const ChartX = () => {
  const userRoles = useAppSelector(state => state.authentication.account.authorities);

  const items = [];

  // Vérification si l'utilisateur a un rôle spécifique
  const hasRole = role => userRoles.includes(role);

  if (hasRole(AUTHORITIES.ADMIN) || hasRole(AUTHORITIES.ADMIN_PEDAGO)) {
    items.push({
      label: `Gestion de scolaire`,
      key: 1,
      children: (
        <div>
          <ChartGestionScolaire />
        </div>
      ),
    });
  }

  if (hasRole(AUTHORITIES.ADMIN) || hasRole(AUTHORITIES.COMPTABLE)) {
    items.push({
      label: `TDB Tresorerie`,
      key: 2,
      children: (
        <div>
          <Tresotory />
        </div>
      ),
    });
  }

  if (hasRole(AUTHORITIES.ADMIN) || hasRole(AUTHORITIES.COMPTABLE)) {
    items.push({
      label: `TDB Comptable`,
      key: 3,
      children: (
        <div>
          <Computable />
        </div>
      ),
    });
  }
  return (
    <>
      <Tabs defaultActiveKey="1" type="card" size={'large'} items={items} />
    </>
  );
};

export default ChartX;
