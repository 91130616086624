import React from 'react';
import { Form, Modal } from 'antd';
import EmploiTempsLigneUpdate from 'app/entities/emploi-temps-ligne/emploi-temps-ligne-update';

const CreateEmploiTempsLigneModal = ({ selectedDay, show, handleClose, emploiId }) => {
  const [form] = Form.useForm();

  function handleSave() {}

  return (
    <Modal width={'40%'} open={show} onOk={() => form.submit()} onCancel={handleClose}>
      <EmploiTempsLigneUpdate selectedDay={selectedDay} form={form} handleClose={handleClose} emploiId={emploiId}></EmploiTempsLigneUpdate>
    </Modal>
  );
};

export default CreateEmploiTempsLigneModal;
