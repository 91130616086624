import React, { useEffect, useState } from 'react';
import './gestion-depart.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesDepart, updateDepart } from 'app/entities/eleve/eleve.reducer';
import { Button, Col, DatePicker, Row, Select, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { IGroupe } from 'app/shared/model/groupe.model';
import { INiveau } from 'app/shared/model/niveau.model';
import { getNiveauEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { IEleve } from 'app/shared/model/eleve.model';

const { Option } = Select;

interface Eleve {
  photoContentType: string;
  photo: string;
  nom: string;
  prenom: string;
  codeMassar: string;
  niveau: string;
  dateDepart: string;
  raisonDepart: string;
}

const GestionDepart: React.FC = () => {
  const dispatch = useAppDispatch();
  const eleve = useAppSelector(state => state.eleve.entities) as IEleve[];
  const niveauList = useAppSelector(state => state.niveau.entities) as INiveau[];
  const groupeList = useAppSelector(state => state.groupe.entities) as IGroupe[];
  const [filterGroupes, setFilterGroupes] = useState<IGroupe[]>(groupeList);

  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedNiveau, setSelectedNiveau] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedEleve, setSelectedEleve] = useState(null);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    dispatch(getNiveaux({}));
    dispatch(getGroupes({}));
    dispatch(getEntitiesDepart({}));
  }, []);

  useEffect(() => {
    if (groupeList.length > 0) {
      setFilterGroupes(groupeList);
    }
  }, [groupeList]);

  useEffect(() => {
    let query = '';
    if (selectedDate) {
      query += `dateDepart.equals=${selectedDate}&`;
    }

    if (selectedGroupe) {
      query += `groupeId.equals=${selectedGroupe}&`;
    }

    if (selectedEleve) {
      query += `Id.equals=${selectedEleve}&`;
    }
    dispatch(
      getEntitiesDepart({
        query: query.slice(0, -1),
      }),
    );
  }, [selectedGroupe, selectedEleve, selectedDate]);

  useEffect(() => {
    if (selectedNiveau) {
      const filtered = groupeList.filter((groupe: IGroupe) => groupe.niveau.id === selectedNiveau);
      setFilterGroupes(filtered);
    } else {
      setFilterGroupes(groupeList);
    }
  }, [selectedNiveau]);

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      setSelectedDate(formattedDate);
    } else {
      setSelectedDate(null);
    }
  };

  const handleNiveauChange = (value: number) => {
    setSelectedNiveau(value);
  };

  const handleGroupeChange = (value: number) => {
    setSelectedGroupe(value);
  };

  const handleEleveChange = (value: number) => {
    setSelectedEleve(value);
  };
  const returnEleve = (id: number) => {
    dispatch(
      updateDepart({
        id: id,
        dateDepart: null,
        raisonDepart: null,
      }),
    );
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'photo',
      key: 'photo',
      render: (text: string, record: IEleve) => (
        <div className="image">
          <img src={`data:${record.photoContentType};base64,${record.photo}`} alt="Eleve" style={{ width: 50, height: 50 }} />
        </div>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Matricule',
      dataIndex: 'codeMassar',
      key: 'codeMassar',
    },
    {
      title: 'Niveau',
      dataIndex: 'niveau',
      key: 'niveau',
    },
    {
      title: 'Date de depart',
      dataIndex: 'dateDepart',
      key: 'dateDepart',
    },
    {
      title: 'Motif',
      dataIndex: 'raisonDepart',
      key: 'raisonDepart',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: IEleve) => (
        <Button type="primary" onClick={() => returnEleve(record.id)}>
          retourner
        </Button>
      ),
    },
  ];

  return (
    <div className="container">
      <h2>Gestion de Départ</h2>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Row gutter={[16, 16]} justify="center">
          {/* Date Input */}
          <Col xs={24} sm={12} md={8}>
            <DatePicker
              onChange={handleDateChange}
              value={selectedDate ? dayjs(selectedDate) : null}
              format="YYYY-MM-DD"
              placeholder="Date d'absence"
            />
          </Col>
          {/* Niveau Select */}
          <Col xs={24} sm={12} md={8}>
            <Select
              value={selectedNiveau}
              onChange={handleNiveauChange}
              style={{ width: 200, color: 'grey' }}
              placeholder="Niveau"
              allowClear
            >
              {niveauList.map(niveau => (
                <Option key={niveau.id} value={niveau.id}>
                  {niveau.libelle}
                </Option>
              ))}
            </Select>
          </Col>
          {/* Groupe Select */}
          <Col xs={24} sm={12} md={8}>
            <Select value={selectedGroupe} onChange={handleGroupeChange} placeholder="Groupe" style={{ width: 200 }} allowClear>
              {filterGroupes.map(groupe => (
                <Option key={groupe.id} value={groupe.id}>
                  {groupe.nom}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Select
              showSearch
              value={selectedEleve}
              onChange={handleEleveChange}
              placeholder="Eleve"
              style={{ width: 200 }}
              allowClear
              filterOption={(input, option) => {
                const text = option?.label || option?.children;
                return text?.toString().toLowerCase().includes(input.toLowerCase());
              }}
            >
              {eleve.map(eleves => (
                <Option key={eleves.id} value={eleves.id}>
                  {eleves.nom} {eleves.prenom}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>

      {eleve.length > 0 ? (
        <Table columns={columns} dataSource={eleve} rowKey={record => record.codeMassar} pagination={{ pageSize: 10 }} />
      ) : (
        <div className="alert alert-warning mt-4">Aucun élément trouvé Dans l'annee scolaire courante</div>
      )}
    </div>
  );
};

export default GestionDepart;
