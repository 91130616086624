import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getMessages, createChatMessage, changeReadStatusAsync } from 'app/entities/message/message.reducer';
import { IMessage } from 'app/shared/model/message.model';
import dayjs from 'dayjs';
import { MessageSender } from 'app/shared/model/enumerations/MessageSender';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import './chat.css';
import { getEntities as getTutors } from 'app/entities/tuteur/tuteur.reducer';
import { IUser } from 'app/shared/model/user.model';
// import 'antd/dist/antd.css';
import { Button, Dropdown, Input, Menu } from 'antd';
import { MenuOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { TextField } from '@mui/material';

const ChatInterface = () => {
  const dispatch = useAppDispatch();
  const [newMessage, setNewMessage] = useState('');
  const selectedUserIdForChat = useAppSelector(state => state.message.selectedUserIdForChat);
  const selectedTutorNameForChat = useAppSelector(state => state.message.selectedTutorNameForChat);
  const [selectedUserId, setSelectedUserId] = useState(selectedUserIdForChat);
  const [searchTerm, setSearchTerm] = useState(selectedTutorNameForChat);

  const messages = useAppSelector(state => state.message.entities);
  const tutors = useAppSelector(state => state.tuteur.entities);
  const loading = useAppSelector(state => state.tuteur.loading);

  useEffect(() => {
    dispatch(getTutors({ query: `paged=false` }));
    dispatch(getMessages({ query: `paged=false` }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedUserId !== null) {
      fetchMessagesForSelectedUser();
    }
  }, [selectedUserId]);

  const fetchMessagesForSelectedUser = async () => {
    if (selectedUserId !== null) {
      await dispatch(getMessages({ query: `userId.equals=${selectedUserId}&paged=false` }));
      await dispatch(changeReadStatusAsync({ userId: selectedUserId, type: 'USER' }));
    }
  };

  const handleTutorClick = internalUserId => {
    setSelectedUserId(internalUserId);
  };

  const handleInputChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '' || selectedUserId === null) return;

    const message: IMessage = {
      contenu: newMessage,
      dateEnvoi: dayjs(),
      isRead: false,
      messageSender: MessageSender.ADMINISTRATION,
      user: { id: selectedUserId } as IUser,
    };

    dispatch(createChatMessage({ entity: message, queryParams: { query: `userId.equals=${selectedUserId}&paged=false` } })).then(() => {
      fetchMessagesForSelectedUser();
    });

    setNewMessage('');
  };

  const filteredTutors = tutors.filter(tutor => {
    const fullName = `${tutor.internalUser.firstName} ${tutor.internalUser.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const sortedMessages = messages.slice().sort((a, b) => {
    return dayjs(a.dateEnvoi).unix() - dayjs(b.dateEnvoi).unix();
  });

  const getUnreadUserMessagesCount = tutorId => {
    return messages.filter(message => {
      return message.messageSender === MessageSender.USER && !message.isRead && message.user?.id === tutorId;
    }).length;
  };
  const chatHistoryRef = useRef(null);
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const menu = (
    <Menu>
      <Menu.Item key="search">
        <TextField size="small" placeholder="Rechercher des tuteurs..." value={searchTerm} onChange={handleInputChange} fullWidth />
      </Menu.Item>
      {filteredTutors.map(tutor => (
        <Menu.Item key={tutor.id} onClick={() => handleTutorClick(tutor.internalUser.id)}>
          <div className="flex justify-between items-center">
            <span>
              <UserOutlined style={{ marginRight: 8 }} />
              {tutor.internalUser.firstName} {tutor.internalUser.lastName}
            </span>
            {getUnreadUserMessagesCount(tutor.internalUser.id) > 0 && (
              <span
                className="unread-count"
                style={{ backgroundColor: '#1890ff', color: 'white', borderRadius: '50%', padding: '2px 6px', fontSize: '12px' }}
              >
                {getUnreadUserMessagesCount(tutor.internalUser.id)}
              </span>
            )}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="chat-app">
      <div className="people-list hidden md:block">
        <div className="search">
          <input type="text" placeholder="Rechercher des tuteurs..." value={searchTerm} onChange={handleInputChange} />
        </div>
        <ul className="list">
          {filteredTutors.map(tutor => (
            <li
              key={tutor.id}
              className={`flex justify-between items-center p-2 cursor-pointer ${selectedUserId === tutor.internalUser.id ? 'selected-tutor' : ''}`}
              onClick={() => handleTutorClick(tutor.internalUser.id)}
            >
              <span>
                {tutor.internalUser.firstName} {tutor.internalUser.lastName}
              </span>
              {getUnreadUserMessagesCount(tutor.internalUser.id) > 0 && (
                <span
                  className="unread-count"
                  style={{
                    backgroundColor: '#1890ff',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '2px 6px',
                    fontSize: '12px',
                  }}
                >
                  {getUnreadUserMessagesCount(tutor.internalUser.id)}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="mobile-menu md:hidden">
        <Dropdown overlay={menu} trigger={['click']}>
          <Button style={{ width: '100%', marginBottom: '8px' }}>
            <MenuOutlined /> Select Tutor
          </Button>
        </Dropdown>
      </div>
      <div className="chat">
        <div className="chat-header">
          {selectedUserId ? (
            <h2>discussion avec {tutors.find(t => t.internalUser.id === selectedUserId)?.internalUser.lastName}</h2>
          ) : (
            <h2 className="start-chat-message">Cliquez sur un tuteur pour commencer la discussion</h2>
          )}
        </div>
        {selectedUserId && (
          <>
            <div className="chat-history" ref={chatHistoryRef}>
              {loading && <p>Loading messages...</p>}
              {messages.length > 0 ? (
                messages.map((message, i) => (
                  <div
                    key={`message-${i}`}
                    className={`message ${message.messageSender === MessageSender.USER ? 'other-message' : 'my-message'}`}
                  >
                    <div className="message-content">
                      <p>{message.contenu}</p>
                      <div className="message-status">
                        {message.messageSender === MessageSender.ADMINISTRATION && (
                          <>{message.isRead ? <DoneAllIcon style={{ color: 'blue' }} /> : <DoneIcon style={{ color: 'grey' }} />}</>
                        )}
                      </div>
                    </div>
                    <div className="message-date">{dayjs(message.dateEnvoi).format('YYYY-MM-DD HH:mm:ss')}</div>
                  </div>
                ))
              ) : (
                <p>aucun messages trouvé</p>
              )}
            </div>
            <div className="chat-message">
              <input type="text" value={newMessage} onChange={e => setNewMessage(e.target.value)} placeholder="Type your message here..." />
              <button onClick={handleSendMessage}>Envoyer</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatInterface;
