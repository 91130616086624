import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faSort, faSortDown, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { IGroupe } from 'app/shared/model/groupe.model';
import { getEntities as getEmploiTempsLignes } from './emploi-temps-ligne.reducer';
import CreateEmploiTempsLigneModal from 'app/modules/modals/CreateEmploiTempsLigneModal';
import TimeLineEmploiTempsLigneModal from 'app/modules/modals/TimeLineEmploiTempsLigneModal';
import { getPaginationState } from 'react-jhipster';

const containerStyle: React.CSSProperties = {
  padding: '2%',
  backgroundColor: '#f4f4f4',
};

const headerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '2%',
};

const dayBarContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '2%',
  position: 'relative',
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  padding: '0 1%',
  flexWrap: 'nowrap',
};

const dayBarLineStyle: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '0',
  width: '100%',
  height: '2px',
  backgroundColor: 'rgb(2, 2, 44)',
  zIndex: 0,
  transform: 'translateY(-50%)',
};

const dayItemStyle = (selected: boolean): React.CSSProperties => ({
  position: 'relative',
  padding: '1% 2%',
  borderRadius: '50px',
  backgroundColor: selected ? 'red' : '#e0e0e0',
  color: selected ? '#fff' : '#000',
  cursor: 'pointer',
  transition: 'background-color 0.3s, color 0.3s',
  zIndex: 1,
  textAlign: 'center',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  maxWidth: '120px',
  margin: '0 5px',
});

const customTableStyle: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  marginTop: '2%',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100%',
};

const theadStyle: React.CSSProperties = {
  backgroundColor: 'rgb(2, 2, 44)',
  color: '#fff',
};

const thStyle: React.CSSProperties = {
  cursor: 'pointer',
  padding: '1%',
  textAlign: 'left',
  borderBottom: '2px solid #dee2e6',
};

const tbodyStyle: React.CSSProperties = {
  backgroundColor: '#f8f9fa',
};

const trStyle: React.CSSProperties = {
  transition: 'background-color 0.3s',
};

const tdStyle: React.CSSProperties = {
  padding: '1%',
};

const buttonGroupStyle: React.CSSProperties = {
  display: 'flex',
  gap: '5%',
  alignItems: 'center',
};

const modalButtonStyle: React.CSSProperties = {
  backgroundColor: 'grey',
  color: '#fff',
  border: 'none',
};
const iconStyle: React.CSSProperties = {
  marginRight: '5%',
  cursor: 'pointer',
  fontSize: '1em',
};

const daysOfWeek = [
  { name: 'Lundi', value: 'LUNDI' },
  { name: 'Mardi', value: 'MARDI' },
  { name: 'Mercredi', value: 'MERCREDI' },
  { name: 'Jeudi', value: 'JEUDI' },
  { name: 'Vendredi', value: 'VENDREDI' },
  { name: 'Samedi', value: 'SAMEDI' },
  { name: 'Dimanche', value: 'DIMANCHE' },
];

export const EmploiTempsLigne = ({ emploiId }: { emploiId: number }) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const groupes = useAppSelector(state => state.groupe.entities) as IGroupe[];
  const [selectedGroupe, setSelectedGroupe] = useState<IGroupe | null>(null);
  const [selectedDay, setSelectedDay] = useState(() => {
    const currentDayName = new Date().toLocaleDateString('fr-FR', { weekday: 'long' });
    const matchingDay = daysOfWeek.find(day => day.name.toLowerCase() === currentDayName.toLowerCase());
    return matchingDay ? matchingDay.value : daysOfWeek[0].value;
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  useEffect(() => {
    dispatch(getGroupes({}));
  }, [dispatch]);

  const emploiTempsLigneList = useAppSelector(state => state.emploiTempsLigne.entities);
  const loading = useAppSelector(state => state.emploiTempsLigne.loading);
  const totalItems = useAppSelector(state => state.emploiTempsLigne.totalItems);
  const [showModalTimeLine, setShowModalTimeLine] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalTimeLine(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const getAllEntities = () => {
    if (emploiId) {
      dispatch(
        getEmploiTempsLignes({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `emploisTempsId.equals=${emploiId}&groupeId.equals=${selectedGroupe?.id}`,
        }),
      );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getGroupes({}));
  }, [dispatch]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, emploiId, selectedGroupe]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };
  const formatTime = timeString => {
    return new Date(timeString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleDayClick = (day: { value: string }) => {
    setSelectedDay(day.value);
  };

  const filteredEmploiTempsLigneList = emploiTempsLigneList.filter(emploiTempsLigne => emploiTempsLigne.jour === selectedDay);

  return (
    <div style={containerStyle}>
      <CreateEmploiTempsLigneModal selectedDay={selectedDay} emploiId={emploiId} show={showModal} handleClose={handleCloseModal} />
      <TimeLineEmploiTempsLigneModal emploiId={emploiId} show={showModalTimeLine} handleClose={handleCloseModal} />
      <div style={headerStyle}>
        <Button style={modalButtonStyle} onClick={handleOpenModal}>
          <FontAwesomeIcon icon="plus" />
          &nbsp; Créer un nouveau Emploi Temps Ligne
        </Button>
      </div>

      <div style={dayBarContainerStyle}>
        <div style={dayBarLineStyle} />
        {daysOfWeek.map((day, index) => (
          <div
            key={day.value}
            style={{
              ...dayItemStyle(selectedDay === day.value),
              marginLeft: index === 0 ? '0' : '20px',
              marginRight: index === daysOfWeek.length - 1 ? '0' : '20px',
            }}
            onClick={() => handleDayClick(day)}
          >
            {day.name}
          </div>
        ))}
      </div>
      <Table style={customTableStyle}>
        <thead style={theadStyle}>
          <tr>
            <th style={thStyle} onClick={sort('id')}>
              ID <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
            </th>
            <th style={thStyle} onClick={sort('name')}>
              Name <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
            </th>
            <th style={thStyle} onClick={sort('heureDebut')}>
              Heure Debut <FontAwesomeIcon icon={getSortIconByFieldName('heureDebut')} />
            </th>
            <th style={thStyle} onClick={sort('heureFin')}>
              Heure Fin <FontAwesomeIcon icon={getSortIconByFieldName('heureFin')} />
            </th>
            <th style={thStyle}>Actions</th>
          </tr>
        </thead>
        <tbody style={tbodyStyle}>
          {filteredEmploiTempsLigneList.map(emploiTempsLigne => (
            <tr key={emploiTempsLigne.id} style={trStyle}>
              <td style={tdStyle}>{emploiTempsLigne.id}</td>
              <td style={tdStyle}>{emploiTempsLigne.name}</td>
              <td style={tdStyle}>{formatTime(emploiTempsLigne.heureDebut)}</td>
              <td style={tdStyle}>{formatTime(emploiTempsLigne.heureFin)}</td>
              <td style={tdStyle}>
                <div style={buttonGroupStyle}>
                  <FontAwesomeIcon icon={faEye} style={iconStyle} onClick={() => navigate(`/emploi-temps-ligne/${emploiTempsLigne.id}`)} />
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    style={iconStyle}
                    onClick={() => navigate(`/emploi-temps-ligne/${emploiTempsLigne.id}/edit`)}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={iconStyle}
                    onClick={() => navigate(`/emploi-temps-ligne/${emploiTempsLigne.id}/delete`)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default EmploiTempsLigne;
