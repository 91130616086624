import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, message, Popconfirm, Space, Table, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramProject, faEye, faPencilAlt, faPercentage, faPlus, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntities } from './matiere.reducer';
import { getPaginationState } from 'react-jhipster';
import CreateSousMatiereModal from 'app/modules/modals/CreateSousMatiereModal';
import AddCoefficientModal from 'app/modules/modals/AddCoefficientl';

export const Matiere = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const [currentId, setCurrentId] = useState(null);

  const matiereList = useAppSelector(state => state.matiere.entities);
  const loading = useAppSelector(state => state.matiere.loading);
  const totalItems = useAppSelector(state => state.matiere.totalItems);
  const { Text, Title } = Typography;
  const [currentMatiere, setCurrentMatiere] = useState(null);
  const [showSousMatModal, setShowSousMatModal] = useState(false);
  const [showCoeffecientModal, setShowCoeffecientModal] = useState(false);
  const [currentSousMatiere, setCurrentSousMatiere] = useState(null);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const handleCloseModal = () => {
    setShowSousMatModal(false);
    setShowCoeffecientModal(false);
  };

  const handleSyncList = () => {
    getAllEntities();
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter.field,
      order: sorter.order === 'ascend' ? ASC : DESC,
    });
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(currentId));
    message.success('Supprimé');
  };

  const cancelDelete = () => {
    message.error('Annulé');
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
    },
    {
      title: 'Code',
      key: 'Code',

      render: (text, record) =>
        record?.sousMatieres?.map(item => (
          <Tag bordered={false} key={item.id} color="geekblue">
            {item.nom}
            <Button
              onClick={e => {
                e.stopPropagation();
                setCurrentMatiere(record);
                setCurrentSousMatiere(item);
                setShowCoeffecientModal(true);
              }}
              type="link"
              color="info"
              icon={<FontAwesomeIcon icon={faPercentage} />}
            ></Button>
          </Tag>
        )),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/matiere/${record.id}`}>
            <Button type="link" color="info" icon={<FontAwesomeIcon icon={faEye} />}></Button>
          </Link>
          <Link to={`/matiere/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}>
            <Button type="link" color="primary" icon={<FontAwesomeIcon icon={faPencilAlt} />} style={{ color: 'green' }}></Button>
          </Link>
          <Button
            onClick={e => {
              e.stopPropagation();
              setCurrentMatiere(record);
              setShowSousMatModal(true);
            }}
            type="link"
            color="info"
            icon={<FontAwesomeIcon icon={faDiagramProject} />}
          ></Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              setCurrentMatiere(record);
              setCurrentSousMatiere(null);
              setShowCoeffecientModal(true);
            }}
            type="link"
            color="info"
            icon={<FontAwesomeIcon icon={faPercentage} />}
          ></Button>
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer ?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" danger onClick={() => setCurrentId(record.id)} icon={<FontAwesomeIcon icon={faTrash} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <CreateSousMatiereModal matiere={currentMatiere} show={showSousMatModal} handleClose={handleCloseModal} />
      <AddCoefficientModal
        matiere={currentMatiere}
        sousMatiere={currentSousMatiere}
        show={showCoeffecientModal}
        handleClose={handleCloseModal}
      />

      <h2 id="matiere-heading" data-cy="MatiereHeading">
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="orange">
          Matières
        </Tag>
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
          <Link to="/matiere/new">
            <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
              Créer une nouvelle Matière
            </Button>
          </Link>
        </div>
      </h2>
      {
        <Table
          columns={columns}
          dataSource={matiereList}
          rowKey="id"
          pagination={{
            current: paginationState.activePage,
            pageSize: paginationState.itemsPerPage,
            total: totalItems,
            showSizeChanger: true,
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      }
    </div>
  );
};

export default Matiere;
