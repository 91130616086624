import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntity, updateEntity, reset } from './emploi-temps-ligne.reducer';
import { Form, Input } from 'antd';
import moment from 'moment';
import { MaskedInput } from 'app/shared/layout/masks/mask-input';

export const EmploiTempsLigneUpdate = ({ selectedDay, emploiId, handleClose, form }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const emploiTempsLigneEntity = useAppSelector(state => state.emploiTempsLigne.entity);
  const updateSuccess = useAppSelector(state => state.emploiTempsLigne.updateSuccess);
  const jOURValues = ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE'];
  const format = 'HH:mm';

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      form.resetFields();
    } else {
      dispatch(getEntity(id));
    }
  }, [isNew, dispatch, id]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(reset());
      form.resetFields();
      handleClose();
    }
  }, [updateSuccess]);

  const getCurrentDay = () => {
    const dayIndex = new Date().getDay();
    const adjustedDayIndex = (dayIndex + 6) % 7;
    return selectedDay !== undefined ? selectedDay : jOURValues[adjustedDayIndex];
  };

  const validateTimeFormat = value => {
    console.log('heure' + value);
    if (!value) return false; // Handle empty values
    return moment(value, format, true).isValid();
  };

  const onFinish = values => {
    const currentDate = moment().format('YYYY-MM-DD');

    const formattedValues = {
      heureDebut: values.heureDebut
        ? moment(`${currentDate} ${values.heureDebut}`, 'YYYY-MM-DD HH:mm', true).format('YYYY-MM-DDTHH:mm:ss')
        : null,
      heureFin: values.heureFin
        ? moment(`${currentDate} ${values.heureFin}`, 'YYYY-MM-DD HH:mm', true).format('YYYY-MM-DDTHH:mm:ss')
        : null,
      name: values.name,
    };

    const entity = {
      ...emploiTempsLigneEntity,
      ...formattedValues,
      jour: getCurrentDay(),
      emploisTemps: { id: emploiId },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }

    handleClose();
  };

  const defaultValues = () =>
    isNew
      ? {
          heureDebut: null,
          heureFin: null,
          jour: getCurrentDay(),
        }
      : {
          ...emploiTempsLigneEntity,
          emploisTemps: emploiId,
        };

  const containerStyle = {
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '400px',
    margin: '0 auto',
    border: '1px solid #ddd',
  };

  const buttonStyle = {
    borderRadius: '5px',
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'background-color 0.3s, transform 0.3s',
    border: 'none',
    cursor: 'pointer',
  };

  const buttonCloseStyle = {
    ...buttonStyle,
    backgroundColor: 'red',
    color: '#fff',
    marginRight: '10px',
  };

  const buttonCloseHoverStyle = {
    backgroundColor: '#c62828',
    transform: 'scale(1.05)',
  };

  const buttonSubmitStyle = {
    ...buttonStyle,
    backgroundColor: 'rgb(2, 2, 44)',
    color: '#fff',
  };

  const buttonSubmitHoverStyle = {
    backgroundColor: '#000',
    transform: 'scale(1.05)',
  };

  const formLabelStyle = {
    fontSize: '16px',
    fontWeight: '600',
    color: 'rgb(2, 2, 44)',
    marginRight: '10px',
  };

  const inputFieldStyle = {
    borderRadius: '5px',
    border: '1px solid #ddd',
    padding: '10px',
    width: '100%',
  };

  const formItemLayout = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    width: '100%',
  };

  const formItemLabelStyle = {
    flex: '0 0 40%',
    fontSize: '16px',
    fontWeight: '600',
    color: 'rgb(2, 2, 44)',
  };

  const formItemFieldStyle = {
    flex: '1 1 60%',
  };

  return (
    <Form initialValues={defaultValues()} form={form} name="basic" layout="vertical" onFinish={onFinish} style={{ width: '100%' }}>
      <div style={formItemLayout}>
        <div style={formItemLabelStyle}>
          <span style={formLabelStyle}>Séance de:</span>
        </div>
        <div style={formItemFieldStyle}>
          <Form.Item
            name="heureDebut"
            rules={[
              { required: true, message: "Veuillez saisir l'heure de fin!" },
              {
                validator: (_, value) =>
                  validateTimeFormat(value) ? Promise.resolve() : Promise.reject(`L'heure doit être au format ${format}`),
              },
            ]}
          >
            <MaskedInput mask="99:99" style={inputFieldStyle} placeholder="HH:mm" />
          </Form.Item>
        </div>
      </div>

      <div style={formItemLayout}>
        <div style={formItemLabelStyle}>
          <span style={formLabelStyle}>à :</span>
        </div>
        <div style={formItemFieldStyle}>
          <Form.Item
            name="heureFin"
            rules={[
              { required: true, message: "Veuillez saisir l'heure de fin!" },
              {
                validator: (_, value) =>
                  validateTimeFormat(value) ? Promise.resolve() : Promise.reject(`L'heure doit être au format ${format}`),
              },
              // Validation supplémentaire pour s'assurer que heureFin > heureDebut (si nécessaire)
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const heureDebut = getFieldValue('heureDebut');
                  if (!value || !heureDebut) {
                    return Promise.resolve();
                  }
                  const [hDebut, mDebut] = heureDebut.split(':').map(Number);
                  const [hFin, mFin] = value.split(':').map(Number);
                  if (hFin > hDebut || (hFin === hDebut && mFin > mDebut)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("L'heure de fin doit être après l'heure de début!"));
                },
              }),
            ]}
          >
            <MaskedInput mask="99:99" style={inputFieldStyle} placeholder="HH:mm" />
          </Form.Item>
        </div>
      </div>

      <Form.Item
        label={<span style={formLabelStyle}>Matière:</span>}
        name="name"
        rules={[{ required: true, message: 'Veuillez saisir la matière!' }]}
      >
        <Input style={inputFieldStyle} />
      </Form.Item>
    </Form>
  );
};

export default EmploiTempsLigneUpdate;
