import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Modal, Switch } from 'antd';
import { GoLoading } from 'app/shared/loading';
import './timetable.css';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setSelectAllMonths, setTheCurrentAnneeScolaireId, updateSelectedMonths } from 'app/entities/eleve/eleve.reducer';
import { getEntities as GetAnneeScolaire } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import { useParams } from 'react-router-dom';
import useCurrentAnneeScolaire from 'app/shared/util/useCurrentAnneeScolaire';
const CheckboxGroup = Checkbox.Group;

const MoisEleveModal = ({ show, handleClose }) => {
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.emploiTempsLigne.loading);
  const selectAllMonths = useAppSelector(state => state.eleve.selectAllMonths);
  const selectedMois = useAppSelector(state => state.eleve.months).map(
    month => month.charAt(0).toUpperCase() + month.slice(1).toLowerCase(),
  );
  const disabledMois = useAppSelector(state => state.eleve.moisPaiementStatus)
    ?.filter(status => status.begin_paiement)
    .map(status => status.mois.charAt(0).toUpperCase() + status.mois.slice(1).toLowerCase())
    .filter(mois => mois !== null) as string[];

  const mois = ['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui'];

  const moisOptions = mois.map(mois => ({
    label: mois,
    value: mois,
    disabled: disabledMois?.includes(mois),
  }));

  const handleCheckboxChange = checkedValues => {
    dispatch(updateSelectedMonths(checkedValues));
  };

  const { periode } = useCurrentAnneeScolaire();

  return (
    <Modal
      width={'60%'}
      className={'time_table'}
      title={`Année scolaire : ${periode}`}
      open={show}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <GoLoading loading={loading} />
      <Form.Item label="All" name="allmonths" valuePropName="checked">
        <Switch
          defaultChecked={!isNew ? false : selectAllMonths}
          onChange={checked => {
            dispatch(setSelectAllMonths(checked));
          }}
        />
      </Form.Item>
      <CheckboxGroup options={moisOptions} value={selectedMois} onChange={handleCheckboxChange} />
    </Modal>
  );
};

export default MoisEleveModal;
